import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RoutePermission } from "../models";
import App from "./app.component";
import Auth0Login from "./auth/auth0.component";
import Logout from "./auth/logout.component";
import { Calendar } from "./calendar";
import { Integration } from "./integration";
import Layout from "./layout";
import { MeetingHistory } from "./meeting-history";
import { MeetingRoom } from "./meeting-room";
import {
  OnboardingAvailability,
  OnboardingOrganization,
  OnboardingOrganizationJoin,
  OnboardingOrganizationNew,
  OnboardingProfile,
  OnboardingWelcome,
} from "./onboarding";
import Settings from "./settings/settings.components";
import {
  LayoutOnboarding,
  NotFound,
  PrivateRoute,
  Unauthorized,
} from "./shared/components";
import { RoutePermissionType, UserRole } from "./shared/config";
import { WaitingRoom } from "./waiting-room";

function AppRouter() {
  const loginPermission = React.useMemo(
      () => RoutePermission.factory([], RoutePermissionType.OnlyLoginRequired),
      []
    ),
    adminPermission = React.useMemo(
      () =>
        RoutePermission.factory(
          [UserRole.Admin],
          RoutePermissionType.AllRolesRequired
        ),
      []
    );
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route index element={<Auth0Login />} />
          <Route element={<PrivateRoute routePermission={loginPermission} />}>
            {/* onboarding routes */}
            <Route path="onboarding" element={<LayoutOnboarding />}>
              <Route index element={<OnboardingWelcome />}></Route>
              <Route path="profile" element={<OnboardingProfile />}></Route>
              <Route
                path="availability"
                element={<OnboardingAvailability />}></Route>
              <Route
                path="organization"
                element={<OnboardingOrganization />}></Route>
              <Route
                path="new-organization"
                element={<OnboardingOrganizationNew />}></Route>
              <Route
                path="join-organization"
                element={<OnboardingOrganizationJoin />}></Route>
            </Route>
            <Route element={<Layout />}>
              {/* Meeting room */}
              <Route path="meeting-room" element={<MeetingRoom />} />
              <Route path="meeting-history" element={<MeetingHistory />} />
              {/* Calendar */}
              <Route path="calendar" element={<Calendar />} />

              {/* Settings */}
              <Route path="settings" element={<Settings />} />

              {/* Waiting Room */}
              <Route path="waiting-room" element={<WaitingRoom />} />
              {/* Integration */}
              <Route path="integration" element={<Integration />} />
            </Route>
            <Route path="logout" element={<Logout />} />
          </Route>
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
