const storageTypes = {
  local: localStorage,
  session: sessionStorage,
};
export enum RequestState {
  idle = "idle",
  pending = "pending",
}

export enum CalendarType {
  Google = "GOOGLE",
  Microsoft = "MICROSOFT",
}

export enum AuthorizationResult {
  Success = 1,
  LoginRequired = 2,
  Unauthorized = 3,
}

export enum RoutePermissionType {
  OneRoleRequired = 1,
  AllRolesRequired = 2,
  OnlyLoginRequired = 3,
}
const keys = {
  auth: "authInfo",
  storage: storageTypes.session,
};
export enum UserRole {
  Admin = "OWNER",
  Member = "MEMBER",
}
const urls = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  schedulingUrl: process.env.REACT_APP_SCHEDULING_URL,
  login: `login`,
  logger: `logger`,
};

const appConstants = {
  keys,
  urls,
};

export default appConstants;
