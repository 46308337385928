import { gql } from "@apollo/client";

export const ADD_SERVICE_PROVIDER_AVAILABILITY = gql`
  mutation AddServiceProviderScheduleMutation(
    $serviceProviderSchedule: ServiceProviderScheduleInput!
  ) {
    addServiceProviderSchedule(
      serviceProviderSchedule: $serviceProviderSchedule
    ) {
      id
      name
      default
      periods {
        dayOfWeek
        startTime
        endTime
      }
      overrides {
        date
        startTime
        endTime
        unavailable
      }
    }
  }
`;

export const UPDATE_SERVICE_PROVIDER_AVAILABILITY = gql`
  mutation UpdateServiceProviderScheduleMutation(
    $serviceProviderSchedule: ServiceProviderScheduleInput!
  ) {
    updateServiceProviderSchedule(
      serviceProviderSchedule: $serviceProviderSchedule
    ) {
      id
      name
      default
      disabled
      periods {
        dayOfWeek
        startTime
        endTime
      }
      overrides {
        date
        startTime
        endTime
        unavailable
      }
    }
  }
`;

export const DELETE_SERVICE_PROVIDER_AVAILABILITY = gql`
  mutation RemoveServiceProviderScheduleMutation(
    $serviceProviderScheduleId: Int!
  ) {
    removeServiceProviderSchedule(
      serviceProviderScheduleId: $serviceProviderScheduleId
    ) {
      id
      name
      default
    }
  }
`;
