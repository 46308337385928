import { useMutation } from "@apollo/client";
import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ADD_SERVICE_PROVIDER_AVAILABILITY } from "../../apollo/availability/availability.mutations";
import {
  IASPeriod,
  IAvailabilitySchedule,
  IServiceProvider,
  OnboardingStep,
} from "../../interfaces";
import { AvailabilityScheduleModel } from "../../models";
import { loggerService, networkService } from "../../services";
import dateService from "../../services/date.service";
import { useAuthStore } from "../../store";
import { SchedulePeriods } from "../shared/components";
import { useServiceProvider } from "../shared/hooks";
import {
  getAuthRedirect,
  getOnboardingStep,
  getProfilePayload,
} from "../shared/utils/helper.util";

function OnboardingAvailability() {
  const [schedule, setSchedule] = React.useState(
    () => new AvailabilityScheduleModel()
  );

  const serviceProvider = useServiceProvider(),
    navigate = useNavigate(),
    updateAuthUser = useAuthStore((state) => state.updateAuthUser),
    [addAvailabilitySchedule] = useMutation(ADD_SERVICE_PROVIDER_AVAILABILITY);

  // set defaults for availability schedule
  React.useEffect(() => {
    setSchedule((availability) => ({
      ...availability,
      name: "Onboarding Schedule (Default)",
      default: true,
    }));
  }, []);

  const handleSubmit = async (values: Partial<IAvailabilitySchedule>) => {
    if (!serviceProvider) return;

    const schedulePayload = {
      ...schedule,
      periods: values.periods.map((period) => {
        return {
          ...period,
          startTime: dateService.convertUserTimeStrToUtcTimeStr(
            period.startTime
          ),
          endTime: dateService.convertUserTimeStrToUtcTimeStr(period.endTime),
        };
      }),
      serviceProviderId: serviceProvider.id,
    };

    try {
      // create default schedule
      await addAvailabilitySchedule({
        variables: {
          serviceProviderSchedule: schedulePayload,
        },
      });

      // update onboarding status
      const userPayload: Partial<IServiceProvider> = {
        ...getProfilePayload(serviceProvider),
        onboardingStatus: "2",
      };

      await networkService.put(
        `${process.env.REACT_APP_OPEN_TELE_URL}api/service-providers`,
        userPayload
      );

      // navigate to the next step ie. organization
      updateAuthUser(userPayload);
      navigate("/onboarding/organization");
    } catch (err) {
      loggerService.error("OnboardingAvailability", err);
    }
  };

  if (!serviceProvider) {
    return null;
  }

  // validate if request is valid
  const step = getOnboardingStep(serviceProvider.onboardingStatus);
  if (step !== OnboardingStep.Profile) {
    const redirectUri = getAuthRedirect(serviceProvider.onboardingStatus);
    return <Navigate to={redirectUri} />;
  }

  return (
    <div className="flex flex-col flex-1 mt-[3.5rem]">
      <div>
        <h3 className="text-2xl font-bold text-gray-800">
          Set your Availability
        </h3>

        <p className="mt-2 text-base text-gray-500">
          When you’re typically available to accept meetings.{" "}
        </p>
      </div>

      <Formik
        initialValues={{
          periods: schedule.periods,
        }}
        onSubmit={handleSubmit}>
        <Form className="flex flex-col flex-1">
          <Field name="periods">
            {(props: FieldProps<IASPeriod[], IAvailabilitySchedule>) => (
              <SchedulePeriods {...props} title="Select Days &amp; Set Time" />
            )}
          </Field>

          <div className="flex items-center mt-12 space-x-4">
            <Link
              to="/onboarding"
              className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white">
              Back
            </Link>

            <button
              type="submit"
              className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80">
              Next
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default OnboardingAvailability;
