import { useMutation } from "@apollo/client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { JOIN_SERVICE_PROVIDER_ORGANIZATION } from "../../apollo";
import { IServiceProvider, OnboardingStep } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import { useAuthStore } from "../../store";
import { FieldError } from "../shared/components";
import { useServiceProvider } from "../shared/hooks";
import {
  getAuthRedirect,
  getOnboardingStep,
  getProfilePayload,
} from "../shared/utils/helper.util";

const joinOrgSchema = Yup.object().shape({
  code: Yup.string().required("Access code is requied."),
});

const OnboardingOrganizationJoin: React.FC = () => {
  const serviceProvider = useServiceProvider(),
    navigate = useNavigate(),
    updateAuthUser = useAuthStore((state) => state.updateAuthUser),
    [fieldError, setFieldError] = React.useState<string>(),
    [joinOrganization] = useMutation<{ joinOrganization: IServiceProvider }>(
      JOIN_SERVICE_PROVIDER_ORGANIZATION,
      {
        context: {
          handleErrors: false,
        },
      }
    );

  const handleSubmit = async (values: { code: string }) => {
    try {
      const orgResult = await networkService.post<{
        joinOrganization: IServiceProvider;
      }>(
        `${process.env.REACT_APP_OPEN_TELE_URL}api/service-providers/join-organization`,
        {
          accessCode: values.code,
          email: serviceProvider.email,
        }
      );

      await networkService.put(
        `${process.env.REACT_APP_OPEN_TELE_URL}api/service-providers`,
        {
          ...getProfilePayload(serviceProvider),
          onboardingStatus: "3",
          organization: orgResult?.joinOrganization?.organization,
        }
      );

      const data = await networkService.get<{
        serviceProviderByEmail: IServiceProvider;
      }>(
        `${process.env.REACT_APP_OPEN_TELE_URL}api/service-providers/service-provider-email/${serviceProvider.email}`
      );

      updateAuthUser(data.serviceProviderByEmail);

      // navigate to the next step ie. organization
      navigate("/meeting-room");
    } catch (err) {
      setFieldError(err.message);
      loggerService.error("OnboardingOrganizationNew", err.message);
    }
  };

  if (!serviceProvider) {
    return null;
  }

  // validate if request is valid
  const step = getOnboardingStep(serviceProvider.onboardingStatus);
  if (step !== OnboardingStep.Availability) {
    const redirectUri = getAuthRedirect(serviceProvider.onboardingStatus);
    return <Navigate to={redirectUri} />;
  }

  return (
    <div className="flex flex-col flex-1 mt-[3.5rem]">
      <div>
        <h3 className="text-2xl font-bold text-gray-800">Join Organization</h3>
        <p className="mt-2 text-base text-gray-500">
          Enter your access code to join organization
        </p>
      </div>

      <Formik
        initialValues={{
          code: "",
        }}
        validationSchema={joinOrgSchema}
        onSubmit={handleSubmit}>
        <Form className="flex flex-col flex-1 mt-8 space-y-6">
          <div className="flex-1 space-y-6 ">
            <div>
              <label className="font-medium text-gray-400">Access Code</label>
              <Field
                type="text"
                id="code"
                name="code"
                placeholder="Access code"
                className="block w-full p-3 mt-2 placeholder-gray-400 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
              />
              {/* client errors */}
              <ErrorMessage name="code">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
              {/* server errors */}
              {fieldError && <FieldError message={fieldError} />}
            </div>
          </div>

          <div className="bg-yellow-secondary text-sm rounded-md flex items-center gap-x-2 px-3 py-2">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.14194 16.5017H12.8581C12.9791 15.3999 13.541 14.4905 14.4531 13.4978C14.5567 13.3859 15.2158 12.703 15.2937 12.6059C15.9411 11.797 16.3469 10.8218 16.4644 9.79239C16.5818 8.76303 16.4062 7.72143 15.9576 6.78753C15.509 5.85363 14.8058 5.06543 13.9289 4.51368C13.052 3.96194 12.0371 3.66909 11.001 3.66887C9.96497 3.66865 8.94994 3.96106 8.0728 4.51243C7.19566 5.0638 6.49209 5.85171 6.04311 6.78541C5.59414 7.71912 5.41802 8.76065 5.53503 9.79006C5.65205 10.8195 6.05744 11.7949 6.70452 12.604C6.78336 12.7021 7.44427 13.3859 7.54602 13.4969C8.45902 14.4905 9.02094 15.3999 9.14194 16.5017ZM12.8334 18.335H9.16669V19.2517H12.8334V18.335ZM5.27452 13.7517C4.4112 12.673 3.87014 11.3724 3.71368 9.99974C3.55722 8.62703 3.79172 7.23804 4.39017 5.99278C4.98862 4.74753 5.92667 3.69665 7.09627 2.96122C8.26586 2.22579 9.61942 1.83572 11.001 1.83594C12.3826 1.83616 13.736 2.22666 14.9054 2.96247C16.0748 3.69828 17.0125 4.74945 17.6105 5.9949C18.2086 7.24035 18.4426 8.62941 18.2857 10.0021C18.1288 11.3747 17.5874 12.6751 16.7237 13.7535C16.1554 14.4612 14.6667 15.585 14.6667 16.96V19.2517C14.6667 19.7379 14.4735 20.2042 14.1297 20.548C13.7859 20.8919 13.3196 21.085 12.8334 21.085H9.16669C8.68046 21.085 8.21415 20.8919 7.87033 20.548C7.52651 20.2042 7.33336 19.7379 7.33336 19.2517V16.96C7.33336 15.585 5.84377 14.4612 5.27452 13.7517ZM11.9167 9.17202H14.2084L10.0834 14.672V11.0054H7.79169L11.9167 5.50168V9.17294V9.17202Z"
                fill="#FFBF00"
              />
            </svg>
            <p>You can edit this detail later from settings section.</p>
          </div>

          <div className="flex items-center space-x-4">
            <Link
              to="/onboarding"
              className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white">
              Back
            </Link>

            <button
              type="submit"
              className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80">
              Next
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default OnboardingOrganizationJoin;
