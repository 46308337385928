import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { OnboardingStep } from "../../../interfaces";
import { useServiceProvider } from "../hooks";
import { getAuthRedirect } from "../utils/helper.util";
import OnboardingSlider from "./onboarding-slider.component";
import OnboardingSteps from "./onboarding-steps.component";

const LayoutOnboarding: React.FC = () => {
  const serviceProvider = useServiceProvider();

  if (!serviceProvider) {
    return null;
  } else if (serviceProvider.onboardingStatus === OnboardingStep.Organization) {
    // if onboarding is not completed yet, redirect to the onboarding screens
    const redirectUri = getAuthRedirect(serviceProvider.onboardingStatus);
    return <Navigate to={redirectUri} />;
  }

  return (
    <div className="overflow-hidden lg:h-screen lg:flex font-dm-sans">
      <section className="overflow-y-auto lg:w-2/5">
        <div className="flex flex-col w-full max-w-2xl min-h-screen px-8 py-12 mx-auto xl:px-16 md:px-10">
          <OnboardingSteps></OnboardingSteps>
          <Outlet />
        </div>
      </section>

      <OnboardingSlider />
    </div>
  );
};

export default LayoutOnboarding;
