import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";

import { ADD_SERVICE_PROVIDER } from "../../apollo";
import { IServiceProvider } from "../../interfaces";
import { dateService, loggerService, networkService } from "../../services";
import { useAuthStore } from "../../store";
import { useServiceProvider } from "../shared/hooks";
import { helper } from "../shared/utils";
import { getAuthRedirect } from "../shared/utils/helper.util";

const Auth0Login: React.FC = () => {
  const serviceProvider = useServiceProvider(),
    doesProvdierExist = !!serviceProvider?.id;

  const [authToken, setAuthToken] = useAuthStore((state) => [
      state.token,
      state.setAuthToken,
    ]),
    setAuthUser = useAuthStore((state) => state.setAuthUser),
    { user, getIdTokenClaims, isLoading, loginWithRedirect } = useAuth0();

  const [addServiceProvider] = useMutation<{
    addServiceProvider: IServiceProvider;
  }>(ADD_SERVICE_PROVIDER);

  React.useEffect(() => {
    if (doesProvdierExist || isLoading) {
      return;
    }

    if (!user) {
      loginWithRedirect();
      return;
    }

    const getAccessToken = async () => {
      const token = await getIdTokenClaims();
      setAuthToken(token.__raw);
    };

    getAccessToken();
  }, [
    user,
    isLoading,
    doesProvdierExist,
    setAuthToken,
    getIdTokenClaims,
    loginWithRedirect,
  ]);

  // query service by email
  // if not exist, add service provider
  React.useEffect(() => {
    if (doesProvdierExist || !authToken || !user) {
      return;
    }

    const getServiceProvider = async () => {
      try {
        const userTimezone = dateService.getUserTimezone();

        const data = await networkService.get(
          `${process.env.REACT_APP_OPEN_TELE_URL}api/service-providers/service-provider-email/${user.email}`
        );

        let serviceProvider = helper.getPropertyValue<IServiceProvider>(
          data,
          "serviceProviderByEmail"
        );

        // add service provider if doesn't exist one
        if (!serviceProvider) {
          const { addServiceProvider } = await networkService.post<{
            addServiceProvider: IServiceProvider;
          }>(`${process.env.REACT_APP_OPEN_TELE_URL}api/service-providers`, {
            firstName: user.name,
            lastName: user.name,
            email: user.email,
            timeZone: userTimezone,
            active: true,
          });

          serviceProvider = addServiceProvider;
        }

        // if timezone is not set, set it to local timezone
        if (!serviceProvider.timeZone) {
          serviceProvider.timeZone = userTimezone;
        }

        setAuthUser(serviceProvider);
      } catch (error) {
        loggerService.error("error", error);
      }
    };

    getServiceProvider();
  }, [user, authToken, doesProvdierExist, setAuthUser, addServiceProvider]);

  if (doesProvdierExist) {
    const redirectUri = getAuthRedirect(serviceProvider.onboardingStatus);
    return <Navigate to={redirectUri} />;
  }

  return null;
};

export default Auth0Login;
