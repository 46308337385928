import React from "react";

export interface ITabs {
  openTab: number;
  setOpenTab: (x: number) => void;
  tabs: string[];
  tabsContent: React.ReactNode[];
}

const Tabs: React.FC<ITabs> = ({ openTab, setOpenTab, tabs, tabsContent }) => {
  return (
    <div className="bg-whites">
      <ul
        role="tablist"
        className="flex justify-start items-center gap-10 text-gray-500 w-full border-b bg-white">
        {tabs.map((tab, index) => (
          <li key={index} className="cursor-pointer">
            <div
              className={`flex items-center px-2 py-5 -mb-px text-center text-gray-600 border-b-2  lg:px-4 whitespace-nowrap hover:border-gray-300
              ${
                openTab === index &&
                "text-primary border-primary hover:border-primary"
              }`}
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(index);
              }}
              data-toggle="tab"
              role="tablist">
              <span>{tab}</span>
            </div>
          </li>
        ))}
      </ul>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded">
        <div className="px-4 py-5 flex-auto">
          <div className="tab-content tab-space">
            {tabsContent.map((content, index) => (
              <div
                key={index}
                className={openTab === index ? "block" : "hidden"}>
                <div>{content}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
