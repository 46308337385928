import moment from "moment-timezone";
import React from "react";
import { IAppointment } from "../../interfaces";
import { dateService, loggerService, networkService } from "../../services";
import { API_DATE_FORMAT } from "../../services/date.service";
import useCalendarStore from "../../store/calendar/calendar.store";
import { useServiceProvider } from "../shared/hooks";

const useCalendarDayData = (): Record<string, IAppointment[]> => {
  const { id } = useServiceProvider(),
    [appointmentData, setAppointmentData] =
      React.useState<Record<string, IAppointment[]>>(),
    [data, setData] = React.useState<{ appointments: IAppointment[] }>(),
    day = useCalendarStore((state) => moment(state.day)),
    FROM_DATE = day.clone().startOf("day").subtract(1, "M"),
    TO_DATE = day.clone().startOf("day").add(1, "M");

  const URL = `${process.env.REACT_APP_OPEN_TELE_URL}api/scheduling/service-providers/${id}/appointments`;
  const params = new URLSearchParams({
    fromDate: `${FROM_DATE.utc().format(API_DATE_FORMAT)}`,
    toDate: `${TO_DATE.utc().format(API_DATE_FORMAT)}`,
  });

  React.useEffect(() => {
    const getAppointments = async () => {
      try {
        const data = await networkService.get<IAppointment[]>(URL, params);

        setData({ appointments: data });
      } catch (error) {
        loggerService.error(error);
      }
    };
    getAppointments();
  }, [day.month()]);

  React.useEffect(() => {
    if (!data) return;

    const categorisedData = [...data.appointments]
      .sort((a, b) => {
        return dateService
          .parseUTCDateTime(a.date, a.startTime)
          .diff(dateService.parseUTCDateTime(b.date, b.startTime));
      })
      .reduce<Record<string, IAppointment[]>>((result, appointment) => {
        const appointmentDate = dateService.convertUtcDateTimeToUserTimezone(
          appointment.date,
          appointment.startTime,
          API_DATE_FORMAT
        );

        if (!result[appointmentDate]) {
          result[appointmentDate] = [];
        }

        result[appointmentDate].push(appointment);
        return result;
      }, {});

    setAppointmentData(categorisedData);
  }, [data]);

  if (!appointmentData) {
    return null;
  }

  return appointmentData;
};

export default useCalendarDayData;
