import { Outlet } from "react-router-dom";
import { useSidebarStore } from "../store";
import { Sidebar } from "./shared/components";

const Layout: React.FC = () => {
  const [sidebarOpen, toggleSidebar] = useSidebarStore((state) => [
    state.sidebarOpen,
    state.toggleSidebar,
  ]);

  return (
    <div className=" bg-gray-100 lg:flex font-dm-sans">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={toggleSidebar} />
      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
