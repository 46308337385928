import moment from "moment";
import { MeetingRoomAppointmentsProps } from "../../interfaces";
import { dateService } from "../../services";
import { USER_TIME_FORMAT_12H } from "../../services/date.service";

const MeetingRoomAppointments: React.FC<MeetingRoomAppointmentsProps> = ({
  currentPatient,
  waitingList,
  handleChatWithWaiting,
  scheduledList,
  isCheckedIn,
  handleCheckout,
  handleCheckin,
  isCheckingIn,
}: MeetingRoomAppointmentsProps) => {
  return (
    <div className="px-5 w-1/4 h-screen">
      <p className="text-md font-medium my-6">Today's Appointments</p>
      {/* Current Card */}

      <div className="bg-white rounded-lg flex border-l-4 border-green-primary">
        <div className="pl-3 py-4">
          <div className="flex items-center ">
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5 11.7515C15.4997 12.7772 15.1491 13.772 14.5063 14.5712C13.8635 15.3705 12.967 15.9262 11.9653 16.1465L11.4867 14.711C11.9249 14.6388 12.3416 14.4704 12.7068 14.2177C13.0721 13.9651 13.3767 13.6346 13.5988 13.25H11.75C11.3522 13.25 10.9706 13.092 10.6893 12.8107C10.408 12.5294 10.25 12.1478 10.25 11.75V8.75C10.25 8.35218 10.408 7.97064 10.6893 7.68934C10.9706 7.40804 11.3522 7.25 11.75 7.25H13.9535C13.7705 5.80017 13.0647 4.46695 11.9686 3.50051C10.8724 2.53406 9.46134 2.00081 8 2.00081C6.53866 2.00081 5.12755 2.53406 4.03143 3.50051C2.93531 4.46695 2.22952 5.80017 2.0465 7.25H4.25C4.64782 7.25 5.02936 7.40804 5.31066 7.68934C5.59196 7.97064 5.75 8.35218 5.75 8.75V11.75C5.75 12.1478 5.59196 12.5294 5.31066 12.8107C5.02936 13.092 4.64782 13.25 4.25 13.25H2C1.60218 13.25 1.22064 13.092 0.93934 12.8107C0.658035 12.5294 0.5 12.1478 0.5 11.75V8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8V11.7515ZM14 11.75V8.75H11.75V11.75H14ZM2 8.75V11.75H4.25V8.75H2Z"
                fill="#546CCC"
              />
            </svg>
            <p className="text-blue-800 text-xs ml-2 font-bold">CURRENT</p>
          </div>
          {currentPatient ? (
            <div className="mt-2">
              <p className="font-medium text-sm capitalize">
                {currentPatient.name}
              </p>
              {/* <p className="text-gray-400 text-sm">2:30 PM - 3:00 PM</p> */}
            </div>
          ) : (
            <div className="mt-1">
              <p className="text-gray-500">No current patient</p>
            </div>
          )}
        </div>
      </div>
      {/* Next up card */}
      <div className="bg-white rounded-lg flex mt-3 border-l-4 border-primary">
        <div className="px-3 py-4 w-full">
          <div className="flex items-center jus">
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2 10.25H3.5V14H12.5V2H3.5V5.75H2V1.25C2 1.05109 2.07902 0.860322 2.21967 0.71967C2.36032 0.579018 2.55109 0.5 2.75 0.5H13.25C13.4489 0.5 13.6397 0.579018 13.7803 0.71967C13.921 0.860322 14 1.05109 14 1.25V14.75C14 14.9489 13.921 15.1397 13.7803 15.2803C13.6397 15.421 13.4489 15.5 13.25 15.5H2.75C2.55109 15.5 2.36032 15.421 2.21967 15.2803C2.07902 15.1397 2 14.9489 2 14.75V10.25ZM6.5 7.25V5L10.25 8L6.5 11V8.75H0.5V7.25H6.5Z"
                fill="#546CCC"
              />
            </svg>
            <p className="text-blue-800 text-xs ml-2 font-bold">NEXT UP</p>
          </div>

          {waitingList && waitingList.length > 0 ? (
            <div className="flex items-center justify-between w-full mt-2">
              <div className="flex items-center gap-x-2">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.625 5.375C7.79076 5.375 7.94973 5.44085 8.06694 5.55806C8.18415 5.67527 8.25 5.83424 8.25 6C8.25 6.16576 8.18415 6.32473 8.06694 6.44194C7.94973 6.55915 7.79076 6.625 7.625 6.625L1.375 6.625C1.20924 6.625 1.05027 6.55915 0.933058 6.44194C0.815847 6.32473 0.75 6.16576 0.75 6C0.75 5.83424 0.815847 5.67527 0.933058 5.55806C1.05027 5.44085 1.20924 5.375 1.375 5.375L7.625 5.375ZM7.625 0.375C7.79076 0.375 7.94973 0.440848 8.06694 0.558058C8.18415 0.675269 8.25 0.83424 8.25 1C8.25 1.16576 8.18415 1.32473 8.06694 1.44194C7.94973 1.55915 7.79076 1.625 7.625 1.625L1.375 1.625C1.20924 1.625 1.05027 1.55915 0.933058 1.44194C0.815847 1.32473 0.75 1.16576 0.75 1C0.75 0.834239 0.815847 0.675268 0.933058 0.558058C1.05027 0.440848 1.20924 0.375 1.375 0.375L7.625 0.375Z"
                    fill="#B7B9C1"
                  />
                </svg>
                <p className="font-medium text-sm capitalize">
                  {waitingList[0].firstName}
                </p>
              </div>
              <button>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="30" height="30" rx="3" fill="#F5F5F5" />
                  <g clipPath="url(#clip0_2222_26442)">
                    <path
                      d="M11.4683 21.618L7.50001 22.5L8.38201 18.5317C7.80116 17.4453 7.49815 16.232 7.50001 15C7.50001 10.8578 10.8578 7.5 15 7.5C19.1423 7.5 22.5 10.8578 22.5 15C22.5 19.1423 19.1423 22.5 15 22.5C13.768 22.5019 12.5547 22.1989 11.4683 21.618ZM11.6858 20.0333L12.1755 20.2958C13.0444 20.7601 14.0148 21.0021 15 21C16.1867 21 17.3467 20.6481 18.3334 19.9888C19.3201 19.3295 20.0892 18.3925 20.5433 17.2961C20.9974 16.1997 21.1162 14.9933 20.8847 13.8295C20.6532 12.6656 20.0818 11.5965 19.2426 10.7574C18.4035 9.91824 17.3344 9.3468 16.1706 9.11529C15.0067 8.88378 13.8003 9.0026 12.7039 9.45672C11.6076 9.91085 10.6705 10.6799 10.0112 11.6666C9.3519 12.6533 9.00001 13.8133 9.00001 15C9.00001 16.0005 9.24376 16.9635 9.70501 17.8245L9.96676 18.3143L9.47551 20.5245L11.6858 20.0333Z"
                      fill="#546CCC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2222_26442">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(6 6)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          ) : (
            <div className="mt-1">
              <p className="text-gray-500">No waiting patient</p>
            </div>
          )}
        </div>
      </div>

      {/* Scheduled users */}
      <div className="mt-6">
        <div className="flex items-center gap-2">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.75 2.25H14.75C14.9489 2.25 15.1397 2.32902 15.2803 2.46967C15.421 2.61032 15.5 2.80109 15.5 3V15C15.5 15.1989 15.421 15.3897 15.2803 15.5303C15.1397 15.671 14.9489 15.75 14.75 15.75H1.25C1.05109 15.75 0.860322 15.671 0.71967 15.5303C0.579018 15.3897 0.5 15.1989 0.5 15V3C0.5 2.80109 0.579018 2.61032 0.71967 2.46967C0.860322 2.32902 1.05109 2.25 1.25 2.25H4.25V0.75H5.75V2.25H10.25V0.75H11.75V2.25ZM14 8.25H2V14.25H14V8.25ZM10.25 3.75H5.75V5.25H4.25V3.75H2V6.75H14V3.75H11.75V5.25H10.25V3.75ZM3.5 9.75H5V11.25H3.5V9.75ZM7.25 9.75H8.75V11.25H7.25V9.75ZM11 9.75H12.5V11.25H11V9.75Z"
              fill="#09121F"
            />
          </svg>
          <div className="font-semibold uppercase text-xs tracking-widest mt-1">
            Scheduled
          </div>
        </div>
        {scheduledList.length > 0 ? (
          <div className="mt-1 max-h-32 overflow-scroll">
            {scheduledList.map((user) => {
              const currentTime = moment();
              const startTime = moment(
                dateService.convertUtcTimeStrToUserTimeStr(
                  user.startTime,
                  USER_TIME_FORMAT_12H
                ),
                USER_TIME_FORMAT_12H
              );

              const diff = Math.round(startTime.diff(currentTime, "minutes"));
              let difftoDisplay = null;

              if (diff > 0 && diff >= 60) {
                difftoDisplay = dateService.convertUtcTimeStrToUserTimeStr(
                  user.startTime,
                  USER_TIME_FORMAT_12H
                );
              } else {
                difftoDisplay = `in ${diff} mins`;
              }

              if (diff > 0) {
                return (
                  <div
                    key={user.id}
                    className="grid grid-cols-[2fr_1fr] gap-x-1 justify-between items-center text-sm text-gray-600 mt-2">
                    <div className="flex items-center gap-3">
                      <svg
                        width="9"
                        height="7"
                        viewBox="0 0 9 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.625 5.375C7.79076 5.375 7.94973 5.44085 8.06694 5.55806C8.18415 5.67527 8.25 5.83424 8.25 6C8.25 6.16576 8.18415 6.32473 8.06694 6.44194C7.94973 6.55915 7.79076 6.625 7.625 6.625L1.375 6.625C1.20924 6.625 1.05027 6.55915 0.933058 6.44194C0.815847 6.32473 0.75 6.16576 0.75 6C0.75 5.83424 0.815847 5.67527 0.933058 5.55806C1.05027 5.44085 1.20924 5.375 1.375 5.375L7.625 5.375ZM7.625 0.375C7.79076 0.375 7.94973 0.440848 8.06694 0.558058C8.18415 0.675269 8.25 0.83424 8.25 1C8.25 1.16576 8.18415 1.32473 8.06694 1.44194C7.94973 1.55915 7.79076 1.625 7.625 1.625L1.375 1.625C1.20924 1.625 1.05027 1.55915 0.933058 1.44194C0.815847 1.32473 0.75 1.16576 0.75 1C0.75 0.834239 0.815847 0.675268 0.933058 0.558058C1.05027 0.440848 1.20924 0.375 1.375 0.375L7.625 0.375Z"
                          fill="#B7B9C1"
                        />
                      </svg>

                      <span className="capitalize">
                        {user.consumer.firstName} {user.consumer.lastName}
                      </span>
                    </div>
                    <span>{difftoDisplay}</span>
                  </div>
                );
              } else return null;
            })}
          </div>
        ) : (
          <div className="mt-1">
            <p className="text-gray-500">No scheduled patient</p>
          </div>
        )}
      </div>
      <hr className="w-100 h-px my-5 bg-primary border-0" />
      {/* Waiting User */}
      <div className="mt-4">
        <div className="flex items-center gap-3">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5C6.7235 15.5 5.52125 15.1813 4.469 14.6188L0.5 15.5L1.382 11.5325C0.8195 10.4795 0.5 9.27725 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5ZM8 2C4.6865 2 2 4.6865 2 8C2 9.00125 2.2445 9.9635 2.705 10.8245L2.9675 11.315L2.4755 13.5245L4.6865 13.034L5.17625 13.2958C6.03725 13.7563 6.99875 14 8 14C11.3135 14 14 11.3135 14 8C14 4.6865 11.3135 2 8 2ZM8.75 4.25V8H11.75V9.5H7.25V4.25H8.75Z"
              fill="#09121F"
            />
          </svg>

          <p className="font-semibold text-xs uppercase tracking-widest">
            Waiting
          </p>
        </div>
        {waitingList.length > 0 ? (
          <div className="max-h-32 overflow-scroll mt-1">
            {waitingList.map((peer) => {
              return (
                <button
                  // onClick={() => handleChatWithWaiting(peer.data.msId)}
                  key={peer.id}
                  disabled={!isCheckedIn}
                  className="flex justify-between w-full items-center text-sm text-gray-600 mt-2">
                  <div className="flex items-center justify-between gap-3 w-full">
                    <svg
                      width="9"
                      height="7"
                      viewBox="0 0 9 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.625 5.375C7.79076 5.375 7.94973 5.44085 8.06694 5.55806C8.18415 5.67527 8.25 5.83424 8.25 6C8.25 6.16576 8.18415 6.32473 8.06694 6.44194C7.94973 6.55915 7.79076 6.625 7.625 6.625L1.375 6.625C1.20924 6.625 1.05027 6.55915 0.933058 6.44194C0.815847 6.32473 0.75 6.16576 0.75 6C0.75 5.83424 0.815847 5.67527 0.933058 5.55806C1.05027 5.44085 1.20924 5.375 1.375 5.375L7.625 5.375ZM7.625 0.375C7.79076 0.375 7.94973 0.440848 8.06694 0.558058C8.18415 0.675269 8.25 0.83424 8.25 1C8.25 1.16576 8.18415 1.32473 8.06694 1.44194C7.94973 1.55915 7.79076 1.625 7.625 1.625L1.375 1.625C1.20924 1.625 1.05027 1.55915 0.933058 1.44194C0.815847 1.32473 0.75 1.16576 0.75 1C0.75 0.834239 0.815847 0.675268 0.933058 0.558058C1.05027 0.440848 1.20924 0.375 1.375 0.375L7.625 0.375Z"
                        fill="#B7B9C1"
                      />
                    </svg>

                    <div className="flex justify-between w-full">
                      <div>
                        <p className="font-medium text-sm capitalize">
                          {peer.firstName} {peer.lastName}
                        </p>
                      </div>
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        ) : (
          <div className="mt-1">
            <p className="text-gray-500">No waiting patient</p>
          </div>
        )}
      </div>
      <hr className="w-100 h-px my-5 bg-primary border-0" />
      <div className="mt-2">
        {isCheckedIn ? (
          <>
            <p className="font-medium text-sm">Done for the day?</p>
            <button
              className="flex items-center justify-center gap-4 border border-primary px-3 py-1 rounded mt-2"
              onClick={handleCheckout}>
              <span className="text-primary uppercase"> Checkout</span>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.25 11.25H1.91667C1.60725 11.25 1.3105 11.1271 1.09171 10.9083C0.872916 10.6895 0.75 10.3928 0.75 10.0833V1.91667C0.75 1.60725 0.872916 1.3105 1.09171 1.09171C1.3105 0.872916 1.60725 0.75 1.91667 0.75H4.25"
                  stroke="#546CCC"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.33398 8.91659L11.2507 5.99992L8.33398 3.08325"
                  stroke="#546CCC"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.25 6H4.25"
                  stroke="#546CCC"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        ) : (
          <>
            <p className="font-medium">
              Check in to join the meeting and start your session
            </p>
            <button
              disabled={isCheckingIn && true}
              className="flex items-center justify-center gap-4 border border-primary px-3 py-1 rounded mt-2"
              onClick={handleCheckin}>
              <span className="text-primary uppercase">Checkin</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default MeetingRoomAppointments;
