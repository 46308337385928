import { gql } from "@apollo/client";

export const GET_SERVICE_PROVIDER_AVAILABILITIES = gql`
  query serviceProviderSchedules($serviceProviderId: Int!) {
    serviceProviderSchedules(serviceProviderId: $serviceProviderId) {
      id
      name
      default
      periods {
        dayOfWeek
        startTime
        endTime
      }
      overrides {
        date
        startTime
        endTime
        unavailable
      }
    }
  }
`;
