import React from "react";
import { useServiceProvider } from "../shared/hooks";

const Profile = () => {
  const serviceProvider = useServiceProvider(),
    [viewType, setViewType] = React.useState(1);

  return (
    <div className="grid grid-cols-[1fr_2fr] mx-4 gap-x-6">
      <div>
        <p className="text-2xl mb-16">
          Welcome {serviceProvider.firstName} {serviceProvider.lastName}
        </p>
        <div>
          <div
            className="flex mb-5 w-full bg-white rounded-md border border-l-0 border-[#BDC5E3] cursor-pointer"
            onClick={() => setViewType(1)}>
            <div className="bg-yellow-400 w-1 rounded-l-md"></div>
            <div className="flex justify-between items-center px-5 py-4 gap-6 w-full">
              <div>
                <p>Personal Info</p>
                <p className="text-gray-400 text-sm">
                  Name, designation, experience, about clinic etc
                </p>
              </div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.172 8.99998L-3.83699e-07 8.99998L-2.96276e-07 6.99998L12.172 6.99998L6.808 1.63598L8.222 0.221985L16 7.99998L8.222 15.778L6.808 14.364L12.172 8.99998Z"
                  fill="#09121F"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex mb-5 w-full rounded-md bg-white cursor-pointer "
            onClick={() => setViewType(2)}>
            <div className="bg-gray-400 w-1 rounded-l-md"></div>
            <div className="flex justify-between items-center px-5 py-4  gap-6 w-full">
              <div>
                <p>Availability Setting</p>
                <p className="text-gray-400 text-sm">
                  Mark available slots and away from clinic/leaves
                </p>
              </div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.172 8.99998L-3.83699e-07 8.99998L-2.96276e-07 6.99998L12.172 6.99998L6.808 1.63598L8.222 0.221985L16 7.99998L8.222 15.778L6.808 14.364L12.172 8.99998Z"
                  fill="#09121F"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex w-full bg-white rounded-md cursor-pointer"
            onClick={() => setViewType(3)}>
            <div className="bg-gray-400 w-1 rounded-l-md"></div>
            <div className="flex justify-between items-center px-5 py-4 gap-6 w-full">
              <div>
                <p>Organisation Setting</p>
                <p className="text-gray-400 text-sm">
                  Org details, Links, achievements
                </p>
              </div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.172 8.99998L-3.83699e-07 8.99998L-2.96276e-07 6.99998L12.172 6.99998L6.808 1.63598L8.222 0.221985L16 7.99998L8.222 15.778L6.808 14.364L12.172 8.99998Z"
                  fill="#09121F"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {viewType === 1 ? (
        <div className="pt-10 w-full">
          {/* Personal Info */}
          <div className="">
            <p className="text-gray-500 text-sm mb-4">Personal Info</p>
            <div className="p-5 bg-white rounded-md">
              <div className="flex gap-x-10 justify-between mb-5">
                {/*  */}
                <div className="relative">
                  <img
                    className="object-cover w-24 h-24 rounded-full"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                    alt=""
                  />
                  <button className="absolute right-0 bottom-0">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <rect width="34" height="34" rx="17" fill="#166FF6" />
                      <g clipPath="url(#clip0_102_10477)">
                        <path
                          d="M20.728 14.6861L19.314 13.2721L10 22.5861V24.0001H11.414L20.728 14.6861ZM22.142 13.2721L23.556 11.8581L22.142 10.4441L20.728 11.8581L22.142 13.2721ZM12.242 26.0001H8V21.7571L21.435 8.32208C21.6225 8.13461 21.8768 8.0293 22.142 8.0293C22.4072 8.0293 22.6615 8.13461 22.849 8.32208L25.678 11.1511C25.8655 11.3386 25.9708 11.5929 25.9708 11.8581C25.9708 12.1232 25.8655 12.3776 25.678 12.5651L12.243 26.0001H12.242Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_102_10477">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(5 5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
                {/*  */}
                <div className="flex flex-grow items-center justify-between">
                  <div className="flex flex-col gap-y-1">
                    <p className="text-md font-semibold">
                      Dr. {serviceProvider.firstName} {serviceProvider.lastName}
                    </p>
                    <p className="text-sm ">Goodness general physician</p>
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <div className="flex items-center justify-start gap-2">
                      <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.55581 5.12133C5.21264 6.22032 6.16827 7.13044 7.32221 7.756L7.94101 6.93067C8.04052 6.79796 8.18765 6.70459 8.35457 6.66822C8.52149 6.63186 8.69661 6.65502 8.84681 6.73333C9.83683 7.24862 10.9302 7.55852 12.0549 7.64267C12.2305 7.65592 12.3943 7.73169 12.5138 7.85488C12.6333 7.97807 12.6996 8.13963 12.6996 8.30733V11.282C12.6996 11.4471 12.6354 11.6063 12.5193 11.7288C12.4031 11.8513 12.2434 11.9285 12.071 11.9453C11.7 11.982 11.3262 12 10.9496 12C4.95761 12 0.0996094 7.37333 0.0996094 1.66667C0.0996094 1.308 0.118509 0.952 0.157009 0.598667C0.174688 0.434465 0.255698 0.282347 0.384362 0.171756C0.513026 0.0611648 0.680194 -3.55718e-05 0.853509 1.55115e-08H3.97691C4.153 -2.10123e-05 4.32264 0.0631677 4.45199 0.176967C4.58134 0.290767 4.6609 0.446816 4.67481 0.614C4.76317 1.68519 5.08856 2.72645 5.62961 3.66933C5.71183 3.81238 5.73616 3.97916 5.69798 4.13813C5.65979 4.2971 5.56175 4.43723 5.42241 4.532L4.55581 5.12133ZM2.79041 4.68333L4.12041 3.77867C3.74296 3.00273 3.48436 2.17923 3.35251 1.33333H1.50661C1.50241 1.444 1.50031 1.55533 1.50031 1.66667C1.49961 6.63733 5.73041 10.6667 10.9496 10.6667C11.0665 10.6667 11.1834 10.6647 11.2996 10.66V8.902C10.4114 8.77643 9.54674 8.53014 8.73201 8.17067L7.78211 9.43733C7.39967 9.29581 7.02821 9.12873 6.67051 8.93733L6.62991 8.91533C5.25691 8.17115 4.11991 7.08828 3.33851 5.78067L3.31541 5.742C3.11444 5.40133 2.93901 5.04756 2.79041 4.68333Z"
                          fill="#565759"
                        />
                      </svg>
                      <p>808-525-2263</p>
                    </div>
                    <div className="flex items-center justify-start gap-2">
                      <svg
                        width="15"
                        height="12"
                        viewBox="0 0 15 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.10039 0H13.7004C13.886 0 14.0641 0.0702379 14.1954 0.195262C14.3266 0.320286 14.4004 0.489856 14.4004 0.666667V11.3333C14.4004 11.5101 14.3266 11.6797 14.1954 11.8047C14.0641 11.9298 13.886 12 13.7004 12H1.10039C0.914739 12 0.736691 11.9298 0.605416 11.8047C0.47414 11.6797 0.400391 11.5101 0.400391 11.3333V0.666667C0.400391 0.489856 0.47414 0.320286 0.605416 0.195262C0.736691 0.0702379 0.914739 0 1.10039 0ZM13.0004 2.82533L7.45079 7.55867L1.80039 2.81067V10.6667H13.0004V2.82533ZM2.15809 1.33333L7.44309 5.77467L12.6518 1.33333H2.15809Z"
                          fill="#565759"
                        />
                      </svg>
                      <p>{serviceProvider.email}</p>
                    </div>
                  </div>
                  <div>
                    <button className="p-3">
                      <svg
                        width="4"
                        height="14"
                        viewBox="0 0 4 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M3.125 1.375C3.125 0.75625 2.61875 0.25 2 0.25C1.38125 0.25 0.875 0.75625 0.875 1.375C0.875 1.99375 1.38125 2.5 2 2.5C2.61875 2.5 3.125 1.99375 3.125 1.375ZM3.125 12.625C3.125 12.0063 2.61875 11.5 2 11.5C1.38125 11.5 0.874999 12.0063 0.874999 12.625C0.874999 13.2437 1.38125 13.75 2 13.75C2.61875 13.75 3.125 13.2437 3.125 12.625ZM3.125 7C3.125 6.38125 2.61875 5.875 2 5.875C1.38125 5.875 0.875 6.38125 0.875 7C0.875 7.61875 1.38125 8.125 2 8.125C2.61875 8.125 3.125 7.61875 3.125 7Z"
                          fill="black"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {/*  */}
              </div>
              <hr />
              {/* About Doctor */}
              <div className="mt-4 bg-gray-100 p-4 rounded-md">
                <div className="flex items-center justify-between">
                  <p className="text-primary">About Doctor</p>
                  <button className="p-2">
                    <svg
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.1893 0.499756C11.3883 0.499756 11.5788 0.57921 11.7194 0.719842L14.2803 3.2807C14.5735 3.5739 14.5735 4.04851 14.2803 4.34171L6.59718 12.0241C6.47343 12.1486 6.3096 12.2252 6.13523 12.2407L3.31787 12.4973C3.09638 12.517 2.87701 12.4375 2.71951 12.2808C2.56272 12.1233 2.48326 11.9039 2.50296 11.6824L2.7596 8.86504C2.77507 8.69067 2.85171 8.52684 2.97616 8.40309L10.6593 0.719968C10.7999 0.579343 10.9904 0.499882 11.1894 0.499882L11.1893 0.499756ZM9.94475 3.5549L11.4452 5.05536L12.6897 3.81082L11.1893 2.31036L9.94475 3.5549ZM10.3842 6.11576L8.88444 4.616L4.22838 9.27134L4.07862 10.9216L5.72886 10.7718L10.3842 6.11576Z"
                        fill="black"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.25 15.5002H0.25V13.9998H9.25V15.5002Z"
                        fill="black"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex justify-between items-start mt-4 gap-8">
                  <div className="grow basis-0 ">
                    <p className="text-gray-500 text-sm  ">Education</p>
                    <p className="text-base text-gray-600">
                      Lorem Ipsum is simply dummy text
                    </p>
                  </div>
                  <div className="grow basis-0 ">
                    <p className="text-gray-500 text-sm  ">Experience</p>
                    <p className="text-base text-gray-600">
                      Lorem Ipsum is simply dummy text
                    </p>
                  </div>
                  <div className="grow basis-0 ">
                    <p className="text-gray-500 text-sm  ">Availability</p>
                    <p className="text-base text-gray-600">
                      Lorem Ipsum is simply dummy text
                    </p>
                  </div>
                </div>
                <div className="grow basis-0 mt-5">
                  <p className="text-gray-500 text-sm  ">Bio</p>
                  <p className="text-base text-gray-600">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s Lorem Ipsum is
                    simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s
                  </p>
                </div>
              </div>

              {/* Roles */}
              <div className="mt-20">
                <div className="flex justify-between mb-3">
                  <h1 className="text-primary">Roles of the Doctor</h1>
                  <button className="flex gap-x-2 items-center py-2 px-3 text-primary border-2 rounded-md border-primary">
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.5 3.75V14.25"
                        stroke="#546CCC"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.25 9H14.75"
                        stroke="#546CCC"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Add Role
                  </button>
                </div>

                <div>
                  <div className="flex items-center justify-between bg-blue-secondary-light px-2 py-4 rounded-md rounded-b-none">
                    <h1>General Consultation</h1>
                    <button>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 3C9 2.45 8.55 2 8 2C7.45 2 7 2.45 7 3C7 3.55 7.45 4 8 4C8.55 4 9 3.55 9 3ZM9 13C9 12.45 8.55 12 8 12C7.45 12 7 12.45 7 13C7 13.55 7.45 14 8 14C8.55 14 9 13.55 9 13ZM9 8C9 7.45 8.55 7 8 7C7.45 7 7 7.45 7 8C7 8.55 7.45 9 8 9C8.55 9 9 8.55 9 8Z"
                          fill="black"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="bg-grey-secondary4 px-2 py-4">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quo quod, quibusdam, doloremque esse, ipsam delectus quos
                      nihil aspernatur fugiat laboriosam voluptate blanditiis
                      ducimus ratione accusamus ut. Eaque, quisquam facere
                      libero deserunt voluptatem sunt cum quaerat similique nisi
                      atque! Minima enim modi alias saepe repellendus suscipit
                      beatae quibusdam error voluptate quidem!
                      <br />
                      <br />
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Repellendus cupiditate est ut, culpa earum quam excepturi
                      animi in mollitia totam reprehenderit quia libero deserunt
                      iure rerum delectus eos. Assumenda, fugiat et eaque
                      voluptate debitis possimus cupiditate atque vitae saepe
                      esse explicabo minima non doloremque reiciendis neque
                      soluta rem, consequatur eligendi!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : viewType === 2 ? (
        <div className="pt-10 w-full">Availability Setting</div>
      ) : (
        <div className="pt-10 w-full">Organisation Setting</div>
      )}
    </div>
  );
};

export default Profile;
