import moment from "moment-timezone";
import React from "react";
import { CalendarWeekPropsInterface } from "../../interfaces";
import { dateService } from "../../services";
import useCalendarStore from "../../store/calendar/calendar.store";

const CalendarWeek: React.FC<CalendarWeekPropsInterface> = ({
  appointmentData,
}) => {
  const [day, nextDay] = useCalendarStore((state) => [
      moment(state.day),
      state.nextDay,
    ]),
    weekDays = dateService.getWeekDaysList(day),
    [preProcessedAppointments, setPreProcessedAppointments] = React.useState(
      []
    ),
    handleSelectDay = (day: moment.Moment) => {
      nextDay(day);
    };

  React.useEffect(() => {
    const refactorData = () => {
      const preProcessedDate = [];
      for (let i = 0; i < weekDays.length; i++) {
        const appointmentsByHour = Array.from(Array(24), () => []);
        const weekDay = weekDays[i];
        if (
          appointmentData &&
          appointmentData[weekDay.date.format("YYYY-MM-DD")]
        ) {
          const appointments =
            appointmentData[weekDay.date.format("YYYY-MM-DD")];
          for (let j = 0; j < appointments.length; j++) {
            const appointment = appointments[j];
            const startTimeHour = Number(appointment.startTime.slice(0, 2));
            appointmentsByHour[startTimeHour].push(appointment);
          }
        }
        preProcessedDate.push(appointmentsByHour);
      }
      setPreProcessedAppointments(preProcessedDate);
    };
    refactorData();
  }, [weekDays[0].date.format(), appointmentData]);

  return (
    <div className="mx-6 ">
      <div className="flex justify-between items-center ">
        {/* Week days */}
        {weekDays.map((weekDay, index_) => (
          <div className="h-20  w-full text-center" key={index_}>
            <p className="text-gray-500 mb-5">{weekDay.day}</p>
            <span
              onClick={() => handleSelectDay(weekDay.date)}
              className={`cursor-pointer ${
                weekDay.date.format("D") === day.date().valueOf().toString() &&
                "bg-primary text-gray-200 rounded-full p-2"
              }`}>
              {weekDay.date.format("DD")}
            </span>
          </div>
        ))}
      </div>

      <div
        className={`h-screen bg-white scrollbar-hide overflow-y-auto mt-3 border-2`}>
        <div className="flex flex-row items-center divide-x-2 ">
          {/* Week days */}
          {preProcessedAppointments.map((weekDay, weekDayIndex) => (
            <div
              className="w-full grow basis-0 self-stretch"
              key={weekDayIndex}>
              {weekDay.every((innerArray) => innerArray.length === 0) ? (
                <div className={`pattern h-full`}>
                  {Array.from(Array(24), (_, index) => (
                    <div key={index} className="h-16 text-center ">
                      {index === 4 && <p>No Appointments</p>}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {weekDay.map((hr, hrIndex) => (
                    <div
                      key={hrIndex}
                      className="h-16 relative overflow-hidden mb-1">
                      {hr.map((appointment, appIndex) => (
                        <div
                          key={appIndex}
                          className="h-16 border rounded-md bg-grey-secondary3 absolute w-full pl-2 pt-2"
                          style={{
                            left: appIndex * 50,
                            zIndex: appIndex,
                          }}>
                          <span className="text-sm mb-1 flex items-center gap-x-2 capitalize text-black font-normal rounded-md">
                            <svg
                              width="10"
                              height="11"
                              viewBox="0 0 10 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle
                                cx="5"
                                cy="5.5"
                                r="4.5"
                                stroke="#546CCC"
                              />
                            </svg>
                            <p>
                              {appointment.consumer.firstName}{" "}
                              {appointment.consumer.lastName}
                            </p>
                          </span>
                          <p className="text-sm text-grey-secondary2">
                            {dateService.convertUtcDateTimeToUserTimezone(
                              appointment.date,
                              appointment.startTime,
                              "hh:mm A"
                            )}
                            -
                            {dateService.convertUtcDateTimeToUserTimezone(
                              appointment.date,
                              appointment.endTime,
                              "hh:mm A"
                            )}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarWeek;
