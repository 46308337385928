import { gql } from "@apollo/client";

export const ADD_SERVICE_PROVIDER = gql`
  mutation Mutation($serviceProvider: ServiceProviderInput!) {
    addServiceProvider(serviceProvider: $serviceProvider) {
      id
      firstName
      lastName
      email
      timeZone
      active
    }
  }
`;

export const UPDATE_SERVICE_PROVIDER = gql`
  mutation Mutation($serviceProvider: ServiceProviderInput!) {
    updateServiceProvider(serviceProvider: $serviceProvider) {
      id
      firstName
      lastName
      email
      timeZone
      active
      addresses {
        id
        zipCode
        state
      }
    }
  }
`;

export const DISABLE_SERVICE_PROVIDER_CALENDAR = gql`
  mutation DisableServiceProviderCalendarTypeMutation(
    $serviceProvider: ServiceProviderDisableCalendarType!
  ) {
    disableServiceProviderCalendarType(serviceProvider: $serviceProvider) {
      calendarType
      isIntegrated
    }
  }
`;

export const DISABLE_SERVICE_PROVIDER_LOCATION = gql`
  mutation DisableServiceProviderLocationTypeMutation(
    $serviceProvider: ServiceProviderDisableLocationType!
  ) {
    disableServiceProviderLocationType(serviceProvider: $serviceProvider) {
      locationType
      isIntegrated
    }
  }
`;

export const ADD_SERVICE_PROVIDER_ORGANIZATION = gql`
  mutation Mutation($organization: OrganizationInput!) {
    addOrganization(organization: $organization) {
      id
      name
      accessCode
    }
  }
`;

export const JOIN_SERVICE_PROVIDER_ORGANIZATION = gql`
  mutation Mutation($joinOrganization: JoinOrganizationInput!) {
    joinOrganization(joinOrganization: $joinOrganization) {
      organization {
        id
        name
        accessCode
      }
    }
  }
`;
