import moment from "moment-timezone";
import React, { useRef } from "react";
import { CalendarMonthPropsInterface, IAppointment } from "../../interfaces";
import { dateService } from "../../services";
import useCalendarStore from "../../store/calendar/calendar.store";
import { useMonth } from "../shared/hooks";
import DetailModal from "./calendar-detail-modal.component";

function useOutsideAlerter(ref, setModalPosition) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setModalPosition({
          x: -1000,
          y: -1000,
        });
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const CalendarMonth: React.FC<CalendarMonthPropsInterface> = ({
  appointmentData,
}) => {
  const weekDays = dateService.getWeekDays(),
    day = useCalendarStore((state) => moment(state.day)),
    [month, setMonth] = React.useState(day),
    [modalPosition, setModalPosition] = React.useState({
      x: -1000,
      y: -1000,
      date: moment(),
    }),
    [selectedAppointment, setSelectedAppointment] = React.useState<
      IAppointment[]
    >([]),
    wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setModalPosition);

  React.useEffect(() => {
    setMonth(day);
  }, [day.format()]);

  const monthDays = useMonth(month);

  return (
    <div className="h-screen overflow-y-auto mx-6 scrollbar-hide">
      <div className="flex items-center justify-between text-gray-400 mb-4">
        {weekDays.map((weekDay, index) => (
          <div className="text-center w-full uppercase" key={index}>
            <span>{weekDay}</span>
          </div>
        ))}
      </div>
      <div className="w-full divide-y mb-36 pb-2 border-y-2 border-x-2 ">
        {monthDays?.days.map((week, index) => (
          <div key={index} className="flex divide-x items-center w-full ">
            {week.map((weekDay, ind) => (
              <div
                ref={wrapperRef}
                onClick={(e) => {
                  e.preventDefault();
                  let adjX = 0;
                  let adjY = 0;
                  if (ind === 6 || ind === 5) {
                    adjX = -288;
                  }
                  const len = monthDays?.days.length;
                  if (index === len - 1 || index === len - 2) {
                    adjY = -230;
                  }
                  setModalPosition({
                    x: e.clientX + adjX,
                    y: e.clientY + adjY,
                    date: weekDay.date,
                  });
                  setSelectedAppointment(
                    appointmentData[weekDay.date.format("YYYY-MM-DD")]
                  );
                }}
                className={`bg-white pt-1 pb-5 pl-1 w-full h-32 ${
                  !weekDay.isCurrentMonth
                    ? "text-gray-400"
                    : "cursor-pointer text-gray-600"
                }`}
                key={ind * (index + 1)}>
                <span className="text-2xl ml-3 font-medium ">
                  {" "}
                  {weekDay.date.format("D")}
                </span>
                <div className="py-4 overflow-y-auto h-full">
                  {appointmentData &&
                    appointmentData[weekDay.date.format("YYYY-MM-DD")] &&
                    appointmentData[weekDay.date.format("YYYY-MM-DD")].map(
                      (appointment, index) => (
                        <div
                          key={index}
                          className="flex items-center flex-wrap pl-2 text-xs gap-1 mb-1">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5" cy="5" r="4.5" stroke="#546CCC" />
                          </svg>

                          <span className="uppercase">
                            {dateService.convertUtcDateTimeToUserTimezone(
                              appointment.date,
                              appointment.startTime,
                              "hh:mm a"
                            )}
                          </span>
                          <span className="font-semibold capitalize">
                            {appointment.consumer.firstName}{" "}
                            {appointment.consumer.lastName}
                          </span>
                        </div>
                      )
                    )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <DetailModal
        position={modalPosition}
        selectedAppointment={selectedAppointment}
      />
    </div>
  );
};

export default CalendarMonth;
