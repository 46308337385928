import React from "react";
import calendarcomIcon from "../../assets/images/calendarcom-icon.png";
import MSOfficeIcon from "../../assets/images/office.png";
import { IIntegartionCalendarProps } from "../../interfaces";
import { Toggle } from "../shared/components";

const IntegrationCalendar: React.FC<IIntegartionCalendarProps> = ({ data }) => {
  // const serviceProvider = useServiceProvider(),

  return (
    <section className="mt-10">
      <div>
        <h2 className="font-bold text-xl">Calendar apps</h2>
        <span className="text-grey-primary">
          Schedule Appointments, Gather availability and sync your calendar in
          OpenScheduling.
        </span>
      </div>
      <div className="max-w-5xl grid grid-cols-1 gap-6 mt-6 xl:grid-cols-2 2xl:grid-cols-3">
        <div className="flex items-center justify-between p-6 bg-white rounded-lg">
          <div className="flex items-center space-x-4">
            <img className="h-8" src={calendarcomIcon} alt="Google Calendar" />
            <div className="font-medium text-lg text-gray-700">
              Calendar.com
            </div>
          </div>
          <Toggle enabled={false} onChange={() => {}} />
        </div>

        <div className="flex items-center justify-between p-6 bg-white rounded-lg">
          <div className="flex items-center space-x-4">
            <img className="h-8" src={MSOfficeIcon} alt="Office 365 Calendar" />
            <div className="font-medium text-lg text-gray-700 truncate">
              Office 365 Calendar
            </div>
          </div>
          <Toggle enabled={false} onChange={() => {}} />
        </div>
      </div>
    </section>
  );
};

export default IntegrationCalendar;
