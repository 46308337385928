import { useSidebarStore } from "../../store";
import { Header, Toggle } from "../shared/components";
import IntegrationCalendar from "./integration-calendar.component";

import chromeIcon from "../../assets/images/chrome-icon.png";
import salesforceIon from "../../assets/images/salesforce-icon.png";
import slackIcon from "../../assets/images/slack-icon.png";
import zoomIcon from "../../assets/images/zoom-icon.png";

const Integration: React.FC = () => {
  const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);

  return (
    <div className="overflow-auto">
      <Header title="Integration" setSidebarOpen={toggleSidebar} />
      <div className="p-8">
        <section>
          <div>
            <h2 className="font-medium text-xl">Azodha Integration</h2>
            <span className="text-grey-primary">
              Plugins to help you navigate your work better
            </span>
          </div>
          <div className="grid grid-cols-3 gap-8 mt-6 max-w-5xl ">
            <div className="bg-white p-4 rounded-xl h-56 flex flex-col justify-between">
              <div>
                <div className="flex justify-between ">
                  <div className="flex gap-2">
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.414 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H8.414L10.414 2ZM2 2V16H18V4H9.586L7.586 2H2ZM6 15C6 13.9391 6.42143 12.9217 7.17157 12.1716C7.92172 11.4214 8.93913 11 10 11C11.0609 11 12.0783 11.4214 12.8284 12.1716C13.5786 12.9217 14 13.9391 14 15H6ZM10 10C9.33696 10 8.70107 9.73661 8.23223 9.26777C7.76339 8.79893 7.5 8.16304 7.5 7.5C7.5 6.83696 7.76339 6.20107 8.23223 5.73223C8.70107 5.26339 9.33696 5 10 5C10.663 5 11.2989 5.26339 11.7678 5.73223C12.2366 6.20107 12.5 6.83696 12.5 7.5C12.5 8.16304 12.2366 8.79893 11.7678 9.26777C11.2989 9.73661 10.663 10 10 10Z"
                        fill="#546CCC"
                      />
                    </svg>
                    <div className="font-medium	text-lg	">Patient Info</div>
                  </div>
                  <Toggle enabled={true} onChange={() => {}} />
                </div>
                <div className="mt-6 text-grey-secondary2">
                  Lorem Ipsum is simply dummy text of the printing
                </div>
              </div>
              <button className="font-medium text-sm uppercase w-max">
                Manage Integration
              </button>
            </div>
            <div className="bg-white p-4 rounded-xl h-56 flex flex-col justify-between">
              <div>
                <div className="flex justify-between ">
                  <div className="flex gap-2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2228_25109)">
                        <path
                          d="M20 2C20.552 2 21 2.448 21 3V6.757L19 8.757V4H5V20H19V17.242L21 15.242V21C21 21.552 20.552 22 20 22H4C3.448 22 3 21.552 3 21V3C3 2.448 3.448 2 4 2H20ZM21.778 8.808L23.192 10.222L15.414 18L13.998 17.998L14 16.586L21.778 8.808ZM13 12V14H8V12H13ZM16 8V10H8V8H16Z"
                          fill="#546CCC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2228_25109">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <div className="font-medium	text-lg">Transcript</div>
                  </div>

                  <Toggle enabled={true} onChange={() => {}} />
                </div>
                <div className="mt-6 text-grey-secondary2">
                  Lorem Ipsum is simply dummy text of the printing
                </div>
              </div>
              <button className="font-medium text-sm uppercase w-max">
                Know more
              </button>
            </div>
            <div className="bg-white p-4 rounded-xl h-56 flex flex-col justify-between">
              <div>
                <div className="flex justify-between ">
                  <div className="flex gap-2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2228_25133)">
                        <path
                          d="M9.83037 8.79L8.00037 9.456V13H6.00038V8.05H6.01537L11.2834 6.132C11.5274 6.039 11.7934 5.992 12.0654 6.001C12.6089 6.01455 13.1347 6.19711 13.5696 6.52328C14.0046 6.84946 14.3271 7.30305 14.4924 7.821C14.6784 8.404 14.8484 8.798 15.0024 9.003C15.4678 9.62362 16.0714 10.1272 16.7654 10.4739C17.4594 10.8205 18.2246 11.0007 19.0004 11V13C17.9677 13.0011 16.9476 12.7733 16.0135 12.3329C15.0794 11.8925 14.2546 11.2504 13.5984 10.453L12.9014 14.409L15.0004 16.17V23H13.0004V17.102L10.7304 15.198L10.0034 19.325L3.10938 18.11L3.45738 16.14L8.38137 17.008L9.83037 8.79ZM13.5004 5.5C12.9699 5.5 12.4612 5.28929 12.0862 4.91421C11.7111 4.53914 11.5004 4.03043 11.5004 3.5C11.5004 2.96957 11.7111 2.46086 12.0862 2.08579C12.4612 1.71071 12.9699 1.5 13.5004 1.5C14.0308 1.5 14.5395 1.71071 14.9146 2.08579C15.2897 2.46086 15.5004 2.96957 15.5004 3.5C15.5004 4.03043 15.2897 4.53914 14.9146 4.91421C14.5395 5.28929 14.0308 5.5 13.5004 5.5Z"
                          fill="#546CCC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2228_25133">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <div className="font-medium	text-lg">Activities</div>
                  </div>
                  <Toggle enabled={true} onChange={() => {}} />
                </div>
                <div className="mt-6 text-grey-secondary2">
                  Lorem Ipsum is simply dummy text of the printing
                </div>
              </div>
              <button className="font-medium text-sm uppercase w-max">
                Manage Integration
              </button>
            </div>
          </div>
        </section>

        <IntegrationCalendar />
        <section className="mt-10">
          <div>
            <h2 className="font-bold text-xl">Other apps</h2>
            <span className="text-grey-primary">
              Schedule Appointments, Gather availability and sync your calendar
              in OpenScheduling.
            </span>
          </div>
          <div className="grid grid-cols-3 gap-8 mt-6 max-w-5xl ">
            <div className="bg-white p-4 rounded-xl h-56 flex flex-col justify-between">
              <div>
                <div className="flex justify-between ">
                  <div className="flex gap-3 items-center">
                    <img src={zoomIcon} alt="Zoom logo" />
                    <div>Zoom</div>
                  </div>
                  <Toggle enabled={true} onChange={() => {}} />
                </div>
                <div className="mt-6 text-grey-secondary2">
                  Automatically include zoom meeting details in your events
                </div>
              </div>
              <button className="font-medium text-sm uppercase w-max">
                Manage Integration
              </button>
            </div>
            <div className="bg-white p-4 rounded-xl h-56 flex flex-col justify-between">
              <div>
                <div className="flex justify-between items-center ">
                  <div className="flex gap-3">
                    <img src={slackIcon} alt="slack logo" />

                    <div>Slack</div>
                  </div>

                  <Toggle enabled={true} onChange={() => {}} />
                </div>
                <div className="mt-6 text-grey-secondary2">
                  Receive notification and manage your calendar in slack
                </div>
              </div>
              <button className="font-medium text-sm uppercase w-max">
                Know more
              </button>
            </div>
            <div className="bg-white p-4 rounded-xl h-56 flex flex-col justify-between">
              <div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-3">
                    <img src={salesforceIon} alt="salesforce logo" />

                    <div>Salesforce</div>
                  </div>
                  <Toggle enabled={false} onChange={() => {}} />
                </div>
                <div className="mt-6 text-grey-secondary2">
                  Automatically update Salesforce records as calendly meetings
                  are scheduled
                </div>
              </div>
              <button className="font-medium text-sm uppercase w-max">
                Manage Integration
              </button>
            </div>
            <div className="bg-white p-4 rounded-xl h-56 flex flex-col justify-between">
              <div>
                <div className="flex justify-between ">
                  <div className="flex gap-3 items-center">
                    <img src={chromeIcon} alt="google chrome logo" />

                    <div>Chrome</div>
                  </div>
                  <Toggle enabled={false} onChange={() => {}} />
                </div>
                <div className="mt-6 text-grey-secondary2">
                  Access your events, create Ad hoc meetings and more.
                </div>
              </div>
              <button className="font-medium text-sm uppercase w-max">
                Manage Integration
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Integration;
