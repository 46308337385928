import React from "react";
import { IASPeriod, IWeekDayPeriodsProps } from "../../../../interfaces";
import { ASPeriodModel } from "../../../../models";

const WeekDayPeriods: React.FC<IWeekDayPeriodsProps> = ({
  dayTitle,
  dayPeriods,
  dayOfWeek,
  onPeriodsChange,
}) => {
  const didMountRef = React.useRef(false);
  const [periods, setPeriods] = React.useState<IASPeriod[]>(dayPeriods);

  React.useEffect(() => {
    if (didMountRef.current) {
      onPeriodsChange(dayOfWeek, periods);
    }

    didMountRef.current = true;
  }, [dayOfWeek, periods, onPeriodsChange]);

  const addPeriod = () => {
    setPeriods((periods) => {
      return [...periods, new ASPeriodModel(dayOfWeek)];
    });
  };

  const deletePeriod = (index: number) => {
    if (!index) return;
    setPeriods((periods) => {
      return periods.filter((_, perioIndex) => perioIndex !== index);
    });
  };

  const onTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    periodIndex: number,
    propName: string
  ) => {
    setPeriods((periods) => {
      return periods.map((period, index) => {
        if (index === periodIndex) {
          return { ...period, [propName]: e.target.value };
        }

        return period;
      });
    });
  };

  return (
    <React.Fragment>
      {periods.map((period, index) => (
        <div key={index} className="flex items-center mt-4 space-x-4">
          <input
            type="time"
            value={period.startTime}
            pattern="[0-9]{2}:[0-9]{2}"
            onChange={(e) => onTimeChange(e, index, "startTime")}
            className="block px-3 py-2 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
          />

          <span>-</span>

          <input
            type="time"
            value={period.endTime}
            pattern="[0-9]{2}:[0-9]{2}"
            onChange={(e) => onTimeChange(e, index, "endTime")}
            className="block px-3 py-2 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
          />

          <button
            type="button"
            disabled={!index}
            onClick={() => deletePeriod(index)}
            className="text-gray-500 hover:text-primary disabled:opacity-40 disabled:cursor-not-allowed">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.25 4.5H3.75H15.75"
                stroke="#E21B17"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5H14.25Z"
                stroke="#E21B17"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 8.25V12.75"
                stroke="#E21B17"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5 8.25V12.75"
                stroke="#E21B17"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      ))}

      <button
        type="button"
        onClick={addPeriod}
        className="flex items-center mt-4 space-x-2 text-gray-400 hover:text-primary">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fillRule="evenodd"
            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
            clipRule="evenodd"
          />
        </svg>

        <span>Add new timeslot for {dayTitle}</span>
      </button>

      <hr className="my-6" />
    </React.Fragment>
  );
};

export default WeekDayPeriods;
