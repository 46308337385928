import { UserRole } from "../app/shared/config";

export interface ICalendarIntegartion {
  calendarType: string;
  isIntegrated: boolean;
}

export interface IProviderIntegrations extends IServiceProvider {
  calendarTypes: ICalendarIntegartion[];
  locationTypes: IConversationIntegartion[];
}

export interface IConversationIntegartion {
  locationType: string;
  isIntegrated: boolean;
}

export enum OnboardingStep {
  Welcome = "0",
  Profile = "1",
  Availability = "2",
  Organization = "3",
}

export interface IOrganization {
  id: number;
  name: string;
  accessCode: string;
}

export interface IAddress {
  id?: number;
  zipCode: string;
  state?: string;
  delete?: boolean;
}

export interface IServiceProvider {
  id: number;
  active: boolean;
  firstName: string;
  lastName: string;
  email: string;
  zipCode: string;
  timeZone: string;
  domain: string;
  onboardingStatus: string;
  profilePic: string;
  organization: IOrganization;
  roles: UserRole[];
  addresses: IAddress[];
}

export interface IIntegartionCalendarProps {
  data?: ICalendarIntegartion[];
}

export interface MeetingRoomAppointmentsProps {
  currentPatient: {
    name: string;
  };
  waitingList: Array<{
    attributes;
    birthDate;
    email;
    firstName;
    gender;
    guardian;
    guardianId;
    id;
    lastName;
    timeZone;
  }>;
  handleChatWithWaiting: (id: string) => void;
  scheduledList: Array<{
    name: string;
    time: string;
    id: string;
    startTime: string;

    consumer: {
      firstName: string;
      lastName: string;
    };
  }>;

  isCheckedIn: boolean;
  handleCheckout: () => void;
  handleCheckin: () => void;
  isCheckingIn: boolean;
}

export interface IOnboardingStep {
  name: string;
  status: string;
}

export interface CalendarState {
  day: moment.Moment;
  nextDay: (day?: moment.Moment) => void;
  nextWeek: () => void;
  nextMonth: () => void;
  prevDay: () => void;
  prevWeek: () => void;
  prevMonth: () => void;
}
