import React from "react";
import {
  IASPeriod,
  ISchedulePeriodsProps,
  IWeekDay,
} from "../../../../interfaces";
import { ASPeriodModel } from "../../../../models";
import dateService from "../../../../services/date.service";
import WeekDayPeriods from "./weekday-periods.component";

const initWeekDays = (periods: IASPeriod[]): IWeekDay[] => {
  return dateService.getWeekDays().map((day, index) => {
    const defaultPeriod = new ASPeriodModel(index);
    const dayPeriods = periods.filter((period) => period.dayOfWeek === index);
    return {
      title: day,
      dayOfWeek: index,
      isChecked: !!dayPeriods.length,
      periods: dayPeriods.length ? dayPeriods : [defaultPeriod],
    };
  });
};

const SchedulePeriods: React.FC<ISchedulePeriodsProps> = ({
  form: { setFieldTouched, setFieldValue },
  field: { name, value: periods },
  title,
}) => {
  const didMountRef = React.useRef(false);
  const [weekDays, setWeekDays] = React.useState(() => initWeekDays(periods));

  React.useEffect(() => {
    if (didMountRef.current) {
      const periods = weekDays
        .filter((day) => day.isChecked)
        .map((day) => day.periods)
        .reduce((curr, next) => curr.concat(next), []);

      setFieldTouched(name);
      setFieldValue(name, periods);
    }

    didMountRef.current = true;
  }, [name, setFieldTouched, setFieldValue, weekDays]);

  const toggleWeekDay = (
    e: React.ChangeEvent<HTMLInputElement>,
    dayOfWeek: number
  ) => {
    setWeekDays((weekDays) => {
      return weekDays.map((day) => {
        if (day.dayOfWeek === dayOfWeek) {
          return { ...day, isChecked: e.target.checked };
        }
        return day;
      });
    });
  };

  const setWeekdayPeriods = React.useCallback(
    (dayOfWeek: number, periods: IASPeriod[]) => {
      setWeekDays((weekDays) => {
        return weekDays.map((day) => {
          if (day.dayOfWeek === dayOfWeek) {
            return { ...day, periods };
          }
          return day;
        });
      });
    },
    []
  );

  return (
    <div className="mt-6">
      <label className="text-sm font-medium text-gray-600">{title}</label>

      {weekDays.map((day) => (
        <div key={day.title} className="mt-4">
          <label className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={day.isChecked}
              className="p-2 border-gray-200 rounded-md text-primary"
              onChange={(e) => toggleWeekDay(e, day.dayOfWeek)}
            />
            <span>{day.title}</span>
          </label>

          {day.isChecked && (
            <WeekDayPeriods
              dayTitle={day.title}
              dayPeriods={day.periods}
              dayOfWeek={day.dayOfWeek}
              onPeriodsChange={setWeekdayPeriods}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default SchedulePeriods;
