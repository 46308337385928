import moment from "moment-timezone";
import React from "react";
import { DetailModalProps } from "../../interfaces";
import { dateService } from "../../services";
import { USER_TIME_FORMAT_12H } from "../../services/date.service";

const DetailModal: React.FC<DetailModalProps> = ({
  position,
  selectedAppointment,
}) => {
  return (
    <div
      className="absolute bg-white shadow-2xl rounded-md p-4 w-72 h-64 overflow-auto"
      style={{ left: position.x, top: position.y }}>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-gray-500">
            {moment(position.date).format("dddd")}
          </p>
          <p className="font-semibold">
            {moment(position.date).format("D MMMM, YYYY")}
          </p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <hr className="w-full mt-4 border" />
      {selectedAppointment?.map((appointment, index) => (
        <div key={index} className="flex justify-start gap-3 mt-4">
          <div className="border p-1 w-1 h-1 rounded-full border-primary mt-2"></div>
          <div className="flex flex-col justify-center item-start">
            <span className="text-gray-500">
              {appointment.appointmentType.name}
            </span>
            <span className="font-semibold text-sm  ">
              {dateService.convertUtcDateTimeToUserTimezone(
                appointment.date,
                appointment.startTime,
                USER_TIME_FORMAT_12H
              )}
              -
              {dateService.convertUtcDateTimeToUserTimezone(
                appointment.date,
                appointment.endTime,
                USER_TIME_FORMAT_12H
              )}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DetailModal;
