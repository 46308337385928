import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { IAppointment } from "../../interfaces";
import { dateService, loggerService, networkService } from "../../services";
import { API_TIME_FORMAT } from "../../services/date.service";
import { useSidebarStore } from "../../store";
import { Header } from "../shared/components";
import { useServiceProvider } from "../shared/hooks";
import user from "./photo-patient.svg";
import SharedSvg from "./shared-svg.component";

const MeetingHistory: React.FC = () => {
  const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
  const { id } = useServiceProvider();
  const [data, setData] = useState<IAppointment[]>([]);
  const [sorting, setSorting] = useState<{ name; start; end; duration }>({
    name: 1,
    start: 1,
    end: 1,
    duration: 1,
  });

  const [timeline, setTimeLine] = useState({
    startDate: dateService.getCurrentDate(),
    endDate: dateService.getCurrentDate(),
    includeHistory: "false",
  });

  const URL = `${process.env.REACT_APP_OPEN_TELE_URL}api/scheduling/service-providers/${id}/appointments`;
  let params;
  if (timeline.includeHistory === "false") {
    params = new URLSearchParams({
      fromDate: timeline.startDate,
      toDate: timeline.endDate,
      includeHistory: timeline.includeHistory,
    });
  } else {
    params = new URLSearchParams({
      includeHistory: timeline.includeHistory,
    });
  }

  useEffect(() => {
    const getAppointments = async () => {
      try {
        const data = await networkService.get<IAppointment[]>(URL, params);
        setData(data);
      } catch (error) {
        loggerService.error(error);
      }
    };
    getAppointments();
  }, [timeline, URL]);

  const handleChange = (e) => {
    const currentValue = e.target.value;
    let startDate = "";
    let endDate = "";
    let includeHistory = "false";

    if (currentValue === "today") {
      startDate = dateService.getCurrentDate();
      endDate = dateService.getCurrentDate();
    } else if (currentValue === "this month") {
      const momentObj = dateService.getMomentDate();
      startDate = dateService.getStartOfMonth(momentObj);
      endDate = dateService.getEndOfMonth(momentObj);
    } else if (currentValue === "this week") {
      startDate = dateService.getStartAndEndOfWeek().startOfWeek;
      endDate = dateService.getStartAndEndOfWeek().endOfWeek;
    } else {
      startDate = null;
      endDate = null;
      includeHistory = "true";
    }

    setTimeLine({ startDate, endDate, includeHistory });
  };

  const handleSorting = (e) => {
    console.log(data);

    let sortedVals = [];
    if (e.currentTarget.name === "name") {
      if (sorting.name === 1) {
        sortedVals = data.sort((a, b) =>
          a.consumer.firstName.localeCompare(b.consumer.firstName)
        );
      } else {
        sortedVals = data.sort((a, b) =>
          b.consumer.firstName.localeCompare(a.consumer.firstName)
        );
      }
      setSorting({ ...sorting, name: +!sorting.name });
    } else if (e.currentTarget.name === "start") {
      if (sorting.start === 1) {
        sortedVals = data.sort((a, b) =>
          moment(a.startTime, API_TIME_FORMAT).diff(
            moment(b.startTime, API_TIME_FORMAT)
          )
        );
      } else {
        sortedVals = data.sort((a, b) =>
          moment(b.startTime, API_TIME_FORMAT).diff(
            moment(a.startTime, API_TIME_FORMAT)
          )
        );
      }
      setSorting({ ...sorting, start: +!sorting.start });
    } else if (e.currentTarget.name === "end") {
      if (sorting.end === 1) {
        sortedVals = data.sort((a, b) =>
          moment(a.endTime, API_TIME_FORMAT).diff(
            moment(b.endTime, API_TIME_FORMAT)
          )
        );
      } else {
        sortedVals = data.sort((a, b) =>
          moment(b.endTime, API_TIME_FORMAT).diff(
            moment(a.endTime, API_TIME_FORMAT)
          )
        );
      }
      setSorting({ ...sorting, end: +!sorting.end });
    } else if (e.currentTarget.name === "duration") {
      if (sorting.duration === 1) {
        sortedVals = data.sort(
          (a, b) =>
            a.appointmentType.durationInMinutes -
            b.appointmentType.durationInMinutes
        );
      } else {
        sortedVals = data.sort(
          (a, b) =>
            b.appointmentType.durationInMinutes -
            a.appointmentType.durationInMinutes
        );
      }
      setSorting({ ...sorting, duration: +!sorting.duration });
    }
    setData([...sortedVals]);
  };

  return (
    <>
      <div className="w-full">
        <Header
          title={`Meeting History`}
          setSidebarOpen={toggleSidebar}></Header>
      </div>

      <div className="grid grid-cols-[2fr_1fr]">
        {/* left */}
        <div className="p-6">
          <div className="flex items-center justify-between">
            <div className="">
              <h1 className="font-medium text-base leading-8">
                All Appointments
              </h1>
              <p className="text-xs text-grey-secondary2">
                {data.length} Patients
              </p>
            </div>

            <div className="w-40">
              <select
                name="period"
                className="border-[#546CCC38] rounded-md w-full select-filter"
                onChange={handleChange}>
                <option value="today">Today</option>
                <option value="this week">This week</option>
                <option value="this month">This month</option>
                <option value="all">All</option>
              </select>
            </div>
          </div>

          <div className="mt-8">
            <section className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr] mb-5">
              <div className="flex items-center gap-x-1">
                <p className="text-sm text-grey-secondary2 font-medium	">
                  PATIENT NAME
                </p>
                <button name="name" onClick={handleSorting}>
                  <svg
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.13347 0.0996094H2.98516L5.01902 4.79021H3.86226L3.45549 3.7987H1.63772L1.24366 4.79021H0.0996094L2.13347 0.0996094ZM2.54025 1.45976L1.96822 2.9216H3.11227L2.54025 1.45976Z"
                      fill="#B3B3B3"
                      stroke="#FEFEFE"
                      strokeWidth="0.1"
                    />
                    <path
                      d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z"
                      fill="#B3B3B3"
                      stroke="#FEFEFE"
                      strokeWidth="0.1"
                    />
                    <path
                      d="M8.45558 7.25627V7.40627H8.60558H9.66065C9.72481 7.40627 9.74667 7.42237 9.75141 7.42655C9.75148 7.42772 9.75146 7.42956 9.75116 7.43225C9.75001 7.44228 9.74459 7.46265 9.72528 7.49273C9.72524 7.49281 9.72519 7.49288 9.72514 7.49295L7.86959 10.3525L7.86924 10.3531C7.83227 10.4106 7.79863 10.4177 7.78568 10.4177C7.77272 10.4177 7.73908 10.4106 7.70211 10.3531L7.70177 10.3525L5.84621 7.49295C5.84616 7.49287 5.8461 7.49278 5.84605 7.4927C5.82676 7.46263 5.82135 7.44227 5.8202 7.43225C5.81989 7.42956 5.81987 7.42772 5.81994 7.42655C5.82469 7.42237 5.84655 7.40627 5.91071 7.40627H6.96578H7.11578V7.25627V0.633499C7.11578 0.423973 7.29014 0.249609 7.49967 0.249609H8.07169C8.28121 0.249609 8.45558 0.423974 8.45558 0.633499V7.25627Z"
                      fill="#B3B3B3"
                      stroke="#FEFEFE"
                      strokeWidth="0.3"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex items-center gap-x-1">
                <p className="text-sm text-grey-secondary2 font-medium	">
                  START TIME
                </p>
                <button name="start" onClick={handleSorting}>
                  <SharedSvg />
                </button>
              </div>
              <div className=" flex items-center gap-x-1">
                <p className="text-sm text-grey-secondary2 font-medium	">
                  END TIME
                </p>
                <button name="end" onClick={handleSorting}>
                  <SharedSvg />
                </button>
              </div>
              <div className="flex items-center gap-x-1">
                <p className="text-sm text-grey-secondary2 font-medium	">
                  DURATION
                </p>
                <button name="duration" onClick={handleSorting}>
                  <SharedSvg />
                </button>
              </div>
              <div className="flex items-center gap-x-1">
                <p className="text-sm text-grey-secondary2 font-medium	">
                  STATUS
                </p>
                <button>
                  <SharedSvg />
                </button>
              </div>
            </section>

            <section className="grid gap-y-1">
              {data.length > 0 ? (
                data.map(
                  ({
                    id,
                    startTime,
                    endTime,
                    status,
                    consumer: { firstName, lastName, birthDate, gender },
                    appointmentType: { durationInMinutes },
                  }) => {
                    return (
                      <div
                        key={id}
                        className="grid items-center rounded-lg py-4 px-2 grid-cols-[2fr_1fr_1fr_1fr_1fr] bg-white">
                        <div className="flex items-center gap-x-2">
                          <img src={user} alt="" />
                          <div>
                            <p className="font-medium	capitalize">
                              {firstName} {lastName}
                            </p>
                            <p className="text-sm text-grey-secondary2 font-normal">
                              {birthDate
                                ? `${dateService.getConsumerAge(birthDate)}y`
                                : "NA"}{" "}
                              • {gender === "UNKNOWN" ? "NA" : gender} • San
                              Diego, CA
                            </p>
                          </div>
                        </div>

                        <p className="text-grey-secondary2 text-sm	font-normal">
                          {dateService.convertTo12Hours(
                            dateService.convertUtcTimeStrToUserTimeStr(
                              startTime
                            )
                          )}
                        </p>
                        <p className="text-grey-secondary2 text-sm	font-normal">
                          {dateService.convertTo12Hours(
                            dateService.convertUtcTimeStrToUserTimeStr(endTime)
                          )}
                        </p>
                        <p className="text-grey-secondary2 text-sm font-normal">
                          {dateService.convertToHourFormat(durationInMinutes)}
                        </p>
                        <button
                          className={`cursor-auto capitalize w-28	${
                            status.toLowerCase() === "booked"
                              ? "bg-green-primary"
                              : "bg-red-secondary2"
                          } rounded-md py-[0.45rem] text-white`}>
                          {status.toLowerCase()}
                        </button>
                      </div>
                    );
                  }
                )
              ) : (
                <h2 className="text-center">No apppoitments available.</h2>
              )}
            </section>
          </div>
        </div>

        {/* right */}
        <div className="mt-24 mr-5">
          <h1 className="font-semibold mb-4">Transcript</h1>
          <div className="bg-white flex flex-col gap-y-6 p-4 rounded-xl overflow-scroll h-[35rem]">
            <div>
              <h1 className="text-gray-400 mb-2">Christ Williams</h1>
              <p className="text-sm">
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
            <div>
              <h1 className="text-gray-400 mb-2">Dr. Smith</h1>
              <p className="text-sm">
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
            <div>
              <h1 className="text-gray-400 mb-2">Christ Williams</h1>
              <p className="text-sm">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
            <div>
              <h1 className="text-gray-400 mb-2">Dr. Smith</h1>
              <p className="text-sm">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
            <div>
              <h1 className="text-gray-400 mb-2">Dr. Smith</h1>
              <p className="text-sm">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
            <div>
              <h1 className="text-gray-400 mb-2">Dr. Smith</h1>
              <p className="text-sm">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
            <div>
              <h1 className="text-gray-400 mb-2">Dr. Smith</h1>
              <p className="text-sm">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
            <div>
              <h1 className="text-gray-400 mb-2">Dr. Smith</h1>
              <p className="text-sm">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingHistory;
