import React from "react";
import { useSidebarStore } from "../../store";
import { Header } from "../shared/components";
import Tabs from "../shared/components/tabs.component";
import Profile from "./settings-profile.component";

const Settings = () => {
  const [openTab, setOpenTab] = React.useState(0);

  const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
  return (
    <div>
      <Header title="Settings" setSidebarOpen={toggleSidebar} />
      <Tabs
        openTab={openTab}
        setOpenTab={setOpenTab}
        tabs={["Personal", "Notifications", "BAA"]}
        tabsContent={[
          <Profile key={1} />,
          <div key={2}>Notifications</div>,
          <div key={3}>BAA</div>,
        ]}
      />
    </div>
  );
};

export default Settings;
