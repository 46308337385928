import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { IAddress, IServiceProvider, OnboardingStep } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import dateService from "../../services/date.service";
import { useAuthStore } from "../../store";
import { FieldError, ProfilePicture } from "../shared/components";
import { useServiceProvider } from "../shared/hooks";
import {
  getAuthRedirect,
  getOnboardingStep,
  getProfilePayload,
} from "../shared/utils/helper.util";

const profileSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is requied."),
  timeZone: Yup.string().required("Timezone is requied."),
  zipCode: Yup.number().typeError("Zip code must be a number.").nullable(true),
});

function OnboardingProfile() {
  const serviceProvider = useServiceProvider(),
    navigate = useNavigate(),
    updateAuthUser = useAuthStore((state) => state.updateAuthUser),
    timezones = React.useMemo(() => dateService.getTimezones(), []);

  const handleSubmit = async (profile: Partial<IServiceProvider>) => {
    try {
      const payload = {
        ...getProfilePayload(serviceProvider),
        firstName: profile.firstName,
        lastName: profile.lastName,
        timeZone: profile.timeZone,
        profilePic: profile.profilePic,
        zipCode:
          Array.isArray(profile.addresses) && profile.addresses.length >= 1
            ? profile.addresses[0].zipCode
            : null,

        onboardingStatus: "1",
        addresses: profile.addresses,
      };

      await networkService.put<any>(
        `${process.env.REACT_APP_OPEN_TELE_URL}api/service-providers`,
        payload
      );

      // navigate to the next step ie. organization
      updateAuthUser(payload);
      navigate("/onboarding/availability");
    } catch (err) {
      loggerService.error("OnboardingProfile", err);
    }
  };

  if (!serviceProvider) {
    return null;
  }

  // validate if request is valid
  const step = getOnboardingStep(serviceProvider.onboardingStatus);
  if (step !== OnboardingStep.Welcome) {
    const redirectUri = getAuthRedirect(serviceProvider.onboardingStatus);
    return <Navigate to={redirectUri} />;
  }

  const handleAddZipCode = (
    values: Partial<IServiceProvider>,
    setFieldValue: {
      (field: string, value: any, shouldValidate?: boolean): void;
      (arg0: string, arg1: string | any[]): void;
    }
  ) => {
    const newZipCode = [...values.addresses, { zipCode: values.zipCode }];
    setFieldValue("addresses", newZipCode);

    setFieldValue("zipCode", "");
  };

  const handleRemoveZipCode = (
    values: Partial<IServiceProvider>,
    address: IAddress,
    index: number,
    setFieldValue: {
      (field: string, value: any, shouldValidate?: boolean): void;
      (arg0: string, arg1: any): void;
    }
  ) => {
    const newZipCode = [...values.addresses];
    newZipCode.splice(index, 1);
    setFieldValue("addresses", newZipCode);
  };

  return (
    <div className="flex flex-col flex-1 mt-[3.5rem]">
      <div>
        <h3 className="text-2xl font-bold text-gray-800">About You</h3>

        <p className="mt-2 text-base text-gray-500">
          We need information about you to create your profile
        </p>
      </div>

      <Formik
        initialValues={{
          firstName: serviceProvider.firstName || "",
          lastName: serviceProvider.lastName || "",
          timeZone: serviceProvider.timeZone || "",
          profilePic: serviceProvider.profilePic || "",
          zipCode: serviceProvider.zipCode || "",
          addresses: [],
        }}
        validationSchema={profileSchema}
        onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form className="flex flex-col flex-1 mt-8">
            <div className="flex-1 space-y-6 ">
              <div>
                <Field name="profilePic">
                  {(props: FieldProps<string>) => <ProfilePicture {...props} />}
                </Field>
              </div>

              <div className="flex items-center space-x-4">
                <div className="w-1/2">
                  <label className="font-medium text-gray-400">
                    First Name
                  </label>
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First name"
                    className="block w-full p-3 mt-2 placeholder-gray-400 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  />
                  <ErrorMessage name="firstName">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </div>

                <div className="w-1/2">
                  <label className="font-medium text-gray-400">Last Name</label>
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last name"
                    className="block w-full p-3 mt-2 placeholder-gray-400 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label className="font-medium text-gray-400">Email</label>
                <p className="block w-full p-3 mt-2 border border-gray-300 bg-slate-100 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                  {serviceProvider.email}
                </p>
              </div>

              <div>
                <label className="font-medium text-gray-400">
                  Select Timezone
                </label>
                <Field
                  as="select"
                  id="timeZone"
                  name="timeZone"
                  className="block w-full p-3 mt-2 placeholder-gray-400 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                  {timezones.map((timezone) => (
                    <option key={timezone.key} value={timezone.key}>
                      {timezone.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="timeZone">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </div>

              <div className="relative">
                <label className="font-medium text-gray-400">Zip Code</label>
                <Field
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  placeholder="Zip code"
                  className=" block w-full p-3 mt-2 placeholder-gray-400 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                />
                <button
                  className="absolute right-5 top-10 px-6 py-1 font-medium text-center text-white capitalize transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80 "
                  type="button"
                  onClick={() => handleAddZipCode(values, setFieldValue)}>
                  Add
                </button>
                <ErrorMessage name="zipCode">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>

                <div className="p-5 flex flex-col divide-y-2 ">
                  {values?.addresses?.map((address, index) => {
                    return (
                      <div key={index} className="">
                        {!address.delete && (
                          <div className="flex justify-between mb-1 mt-1">
                            <p>{address.zipCode}</p>
                            <button
                              type="button"
                              onClick={() =>
                                handleRemoveZipCode(
                                  values,
                                  address,
                                  index,
                                  setFieldValue
                                )
                              }>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="red"
                                className="w-5 h-5">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="flex items-center mt-12 space-x-4">
              <Link
                to="/onboarding"
                className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white">
                Back
              </Link>

              <button
                type="submit"
                className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80">
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default OnboardingProfile;
