import produce from "immer";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import { SidebarState } from "../../interfaces";

const useSidebarStore = create<SidebarState>(
  persist(
    devtools(
      (set) => ({
        sidebarOpen: false,
        toggleSidebar: (open) =>
          set(
            produce((state) => {
              state.sidebarOpen = open;
            }),
            false,
            "sidebar/toggleSidebar"
          ),
      }),
      { name: "sidebar", serialize: true }
    ),
    {
      name: "sidebar", // unique name
      getStorage: () => appConstants.keys.storage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useSidebarStore;
