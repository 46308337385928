import { RoutePermissionType, UserRole } from "../app/shared/config";

export class RoutePermission {
  permissions: UserRole[];
  permissionType: RoutePermissionType;

  constructor(permissions: UserRole[], permissionType: RoutePermissionType) {
    this.permissions = permissions;
    this.permissionType = permissionType;
  }

  public static factory(
    permissions: UserRole[],
    permissionType: RoutePermissionType
  ): RoutePermission {
    return new RoutePermission(permissions, permissionType);
  }
}
