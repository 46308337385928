import { gql } from "@apollo/client";

export const GET_ALL_SERVICE_PROVIDERS = gql`
  query Query {
    serviceProviders {
      id
      firstName
      lastName
      email
      roles
      organization {
        id
        name
      }
    }
  }
`;

export const GET_ALL_SERVICE_PROVIDERS_FROM_ORGANIZATION = gql`
  query Query($organizationId: Int!) {
    serviceProvidersByOrganization(organizationId: $organizationId) {
      id
      firstName
      lastName
      email
      active
      roles
      organization {
        id
        name
      }
    }
  }
`;

export const GET_SERVICE_PROVIDER = gql`
  query Query($serviceProviderId: Int!) {
    serviceProvider(id: $serviceProviderId) {
      id
      firstName
      lastName
      email
      timeZone
      active
      calendarTypes {
        calendarType
        isIntegrated
      }
      locationTypes {
        locationType
        isIntegrated
      }
    }
  }
`;

export const GET_SERVICE_PROVIDER_BY_EMAIL = gql`
  query Query($email: String!) {
    serviceProviderByEmail(email: $email) {
      id
      firstName
      lastName
      email
      roles
      zipCode
      timeZone
      active
      profilePic
      onboardingStatus
      addresses {
        id
        zipCode
        state
      }
      organization {
        id
        name
        accessCode
      }
    }
  }
`;

export const GET_SERVICE_PROVIDER_ORGANIZATION = gql`
  query Query($name: String!) {
    organizationByName(name: $name) {
      id
      name
    }
  }
`;
