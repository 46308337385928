const SharedSvg = () => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.78566 7.15666V7.30666H2.93566H3.99072C4.05489 7.30666 4.07675 7.32276 4.08149 7.32694C4.08156 7.32811 4.08154 7.32995 4.08123 7.33265C4.08009 7.34267 4.07466 7.36304 4.05536 7.39313C4.05531 7.3932 4.05527 7.39327 4.05522 7.39334L2.19967 10.2529L2.19932 10.2535C2.16235 10.311 2.12871 10.318 2.11576 10.318C2.1028 10.318 2.06916 10.311 2.03219 10.2535L2.03184 10.2529L0.176289 7.39334C0.176234 7.39326 0.176181 7.39317 0.176127 7.39309C0.156842 7.36302 0.151424 7.34266 0.150276 7.33265C0.149968 7.32995 0.149953 7.32811 0.150022 7.32694C0.154765 7.32276 0.176626 7.30666 0.240787 7.30666H1.29585H1.44585V7.15666V0.53389C1.44585 0.324364 1.62022 0.15 1.82974 0.15H2.40177C2.61129 0.15 2.78566 0.324364 2.78566 0.53389V7.15666Z"
        fill="#B3B3B3"
        stroke="#FEFEFE"
        strokeWidth="0.3"
      />
      <path
        d="M7.78566 3.31209V3.16209H7.93566H8.99072C9.05489 3.16209 9.07675 3.14599 9.08149 3.14181C9.08156 3.14064 9.08154 3.1388 9.08123 3.1361C9.08009 3.12608 9.07466 3.10571 9.05536 3.07562C9.05531 3.07555 9.05527 3.07548 9.05522 3.07541L7.19967 0.215824L7.19932 0.215287C7.16235 0.157777 7.12871 0.150701 7.11576 0.150701C7.1028 0.150701 7.06916 0.157777 7.03219 0.215287L7.03184 0.215823L5.17629 3.07541C5.17623 3.07549 5.17618 3.07558 5.17613 3.07566C5.15684 3.10573 5.15142 3.12609 5.15028 3.1361C5.14997 3.1388 5.14995 3.14064 5.15002 3.14181C5.15477 3.14599 5.17663 3.16209 5.24079 3.16209H6.29585H6.44585V3.31209V9.93486C6.44585 10.1444 6.62022 10.3187 6.82974 10.3187H7.40177C7.61129 10.3187 7.78566 10.1444 7.78566 9.93486V3.31209Z"
        fill="#B3B3B3"
        stroke="#FEFEFE"
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default SharedSvg;
