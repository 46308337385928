import { useLazyQuery, useMutation } from "@apollo/client";
import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  GET_SERVICE_PROVIDER_BY_EMAIL,
  UPDATE_SERVICE_PROVIDER,
} from "../../apollo";
import { IServiceProvider, OnboardingStep } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import { useAuthStore } from "../../store";
import { useServiceProvider } from "../shared/hooks";
import {
  getAuthRedirect,
  getOnboardingStep,
  getProfilePayload,
} from "../shared/utils/helper.util";

function OnboardingOrganization() {
  const serviceProvider = useServiceProvider(),
    navigate = useNavigate(),
    updateAuthUser = useAuthStore((state) => state.updateAuthUser),
    [updateServiceProvider] = useMutation<{
      updateServiceProvider: IServiceProvider;
    }>(UPDATE_SERVICE_PROVIDER),
    [getServicePoviderByEmail] = useLazyQuery<{
      serviceProviderByEmail: IServiceProvider;
    }>(GET_SERVICE_PROVIDER_BY_EMAIL);

  if (!serviceProvider) {
    return null;
  }

  const confirmOrganization = async () => {
    try {
      await networkService.put<{
        updateServiceProvider: IServiceProvider;
      }>(`${process.env.REACT_APP_OPEN_TELE_URL}api/service-providers`, {
        ...getProfilePayload(serviceProvider),
        onboardingStatus: "3",
      });

      const data = await networkService.get<{
        serviceProviderByEmail: IServiceProvider;
      }>(
        `${process.env.REACT_APP_OPEN_TELE_URL}api/service-providers/service-provider-email/${serviceProvider.email}`
      );

      updateAuthUser(data.serviceProviderByEmail);

      // navigate to the next step ie. organization
      navigate("/meeting-room");
    } catch (err) {
      loggerService.error("OnboardingOrganization", err.message);
    }
  };

  // validate if request is valid
  const step = getOnboardingStep(serviceProvider.onboardingStatus);
  if (step !== OnboardingStep.Availability) {
    const redirectUri = getAuthRedirect(serviceProvider.onboardingStatus);
    return <Navigate to={redirectUri} />;
  }

  return (
    <div className="flex flex-col flex-1 mt-[3.5rem]">
      {serviceProvider?.organization?.id ? (
        <React.Fragment>
          <div className="flex-1">
            <h3 className="text-2xl font-bold text-gray-800">Organization</h3>
            <p className="mt-2 text-base text-gray-500">
              You are already part of the following organization
            </p>
            <div className="mt-12 space-y-4">
              <h1 className="text-2xl text-gray-900">
                {serviceProvider.organization.name}
              </h1>
            </div>
            <p className="mt-2 text-base text-gray-500">
              Click `Next` to confirm and proceed
            </p>
            <div className="flex items-center mt-12 space-x-4">
              <Link
                to="/onboarding"
                className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white">
                Back
              </Link>

              <button
                type="button"
                onClick={confirmOrganization}
                className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80">
                Next
              </button>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="flex-1">
            <h3 className="text-2xl font-bold text-gray-800">Organization</h3>

            <p className="mt-2 text-base text-gray-500">
              Do you want to create this account new organization or join one?
            </p>

            <div className="mt-12 space-y-4">
              <Link
                to="/onboarding/new-organization"
                className="block w-full px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80">
                Create New Organization
              </Link>

              <Link
                to="/onboarding/join-organization"
                className="block w-full px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white">
                Join Existing Organization
              </Link>
            </div>
          </div>

          <div className="flex items-center justify-center mt-12">
            <Link
              to="/onboarding"
              className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white">
              Back
            </Link>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default OnboardingOrganization;
