import { useVideo } from "@100mslive/react-sdk";
import { peerProps } from "../../interfaces";

const Peer: React.FC<peerProps> = ({ peer }: peerProps) => {
  const { videoRef } = useVideo({
    trackId: peer.videoTrack,
  });
  return (
    <div className="flex w-full h-full relative">
      <video
        ref={videoRef}
        className={`w-full h-full ${
          peer.isLocal && "w-48 h-42 z-10 rounded-lg border-2 border-[#1F1F1F]"
        }`}
        autoPlay
        muted
        playsInline
      />
      {!peer.isLocal && (
        <div className="absolute text-white bg-[#1F1F1F] bottom-0 left-0 p-2">
          {peer.name}
        </div>
      )}
    </div>
  );
};

export default Peer;
