import { HMSRoomProvider } from "@100mslive/react-sdk";
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import AppRouter from "./app/app-router.component";
import { ErrorBoundary } from "./app/shared/components";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <HMSRoomProvider>
        <AppRouter />
        <ToastContainer
          theme="colored"
          bodyClassName="m-0 py-0"
          toastClassName="text-sm font-medium text-gray-800"
        />
      </HMSRoomProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
