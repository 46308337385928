import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { CalendarData, CalendarDay } from "../../interfaces";

const useMonth = (month: Moment): CalendarData | undefined => {
  const [calendarData, setCalendarData] = useState<CalendarData>();

  useEffect(() => {
    const currentMonth = month || moment();
    const startOfMonth = currentMonth.clone().startOf("month");
    const endOfMonth = currentMonth.clone().endOf("month");

    const days: CalendarDay[] = [];
    let day = startOfMonth.clone();

    while (day <= endOfMonth) {
      days.push({ date: day.clone(), isCurrentMonth: true });
      day = day.add(1, "days");
    }

    const firstWeek = moment(startOfMonth).week();
    let week = firstWeek;
    for (let i = 0; i < days.length; i++) {
      if (week !== days[i].date.week()) {
        week = days[i].date.week();
      }
      days[i].date.week(week);
    }

    const calendar: CalendarDay[][] = [];
    for (let i = 0; i < days.length; i++) {
      const week = days[i].date.week();
      if (!calendar[week - firstWeek]) {
        calendar[week - firstWeek] = [];
      }
      calendar[week - firstWeek].push(days[i]);
    }

    // get the first week padding days # padding -> days of past month
    let pastMonthLastDay = currentMonth
      .clone()
      .subtract(1, "months")
      .endOf("month");
    const firstWeekRemainingDays = 7 - calendar[0].length;

    const paddingDays = [];
    for (let i = 0; i < firstWeekRemainingDays; i++) {
      paddingDays.unshift({
        date: pastMonthLastDay.clone(),
        isCurrentMonth: false,
      });
      pastMonthLastDay = pastMonthLastDay.subtract(1, "days");
    }

    calendar[0] = [...paddingDays, ...calendar[0]];

    // get the last week padding days # padding -> days of past month
    let nextMonthLastDay = currentMonth
      .clone()
      .add(1, "months")
      .startOf("month");
    const lastWeekRemainingDays = 7 - calendar[calendar.length - 1].length;

    for (let i = 0; i < lastWeekRemainingDays; i++) {
      calendar[calendar.length - 1].push({
        date: nextMonthLastDay.clone(),
        isCurrentMonth: false,
      });
      nextMonthLastDay = nextMonthLastDay.add(1, "days");
    }
    setCalendarData({ month: currentMonth, days: calendar });
  }, [month]);

  return calendarData;
};

export default useMonth;
