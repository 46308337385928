import moment from "moment-timezone";
import React, { useState } from "react";
import { useSidebarStore } from "../../store";
import useCalendarStore from "../../store/calendar/calendar.store";
import { Header } from "../shared/components";
import CalendarDays from "./calendar-days.component";
import CalendarMonth from "./calendar-month.component";
import CalendarWeek from "./calendar-week.component";
import useCalendarDayData from "./useCalendarDayData";

const Calender: React.FC = () => {
  // viewType 1 -> day , 2 -> week, 3 -> month
  const [viewType, setViewType] = useState(
      parseInt(localStorage.getItem("viewType")) || 3
    ),
    toggleSidebar = useSidebarStore((state) => state.toggleSidebar),
    day = useCalendarStore((state) => moment(state.day)),
    [nextDay, nextWeek, nextMonth] = useCalendarStore((state) => [
      state.nextDay,
      state.nextWeek,
      state.nextMonth,
    ]),
    [prevDay, prevWeek, prevMonth] = useCalendarStore((state) => [
      state.prevDay,
      state.prevWeek,
      state.prevMonth,
    ]);

  React.useEffect(() => {
    localStorage.setItem("viewType", viewType.toString());
  }, [viewType]);

  const handleNext = () => {
    switch (viewType) {
      case 1:
        nextDay();
        break;
      case 2:
        nextWeek();
        break;
      case 3:
        nextMonth();
        break;
      default:
        // Handle default case here
        break;
    }
  };

  const handlePrev = () => {
    switch (viewType) {
      case 1:
        prevDay();
        break;
      case 2:
        prevWeek();
        break;
      case 3:
        prevMonth();
        break;
      default:
        // Handle default case here
        break;
    }
  };

  const appointmentData = useCalendarDayData();

  return (
    <div>
      <Header title="Calendar View" setSidebarOpen={toggleSidebar}></Header>
      {/* Date and view filter */}
      <div className="flex items-center justify-between mt-4 mb-6 px-8 pb-3">
        <div className="flex items-center">
          {/* prev btn */}
          <button className="pr-2" onClick={handlePrev}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.8332 10H4.1665"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.99984 15.8327L4.1665 9.99935L9.99984 4.16602"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {/* next btn */}
          <button className="p-5 pl-2" onClick={handleNext}>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.05518 10H16.7218"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.8887 4.16602L16.722 9.99935L10.8887 15.8327"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button>
            {day.format("DD MM YYYY") === moment().format("DD MM YYYY") &&
              viewType === 1 && <span className="font-semibold">Today . </span>}
          </button>

          <span className="font-medium text-xl">
            {viewType === 1
              ? day.format("DD MMMM, YYYY ")
              : viewType === 2
              ? day.format("MMMM, YYYY")
              : day.format("MMMM, YYYY")}
          </span>
        </div>
        <div className="rounded-md">
          <button
            onClick={() => setViewType(1)}
            className={`px-8 py-1.5 rounded-l-md border-[1px] border-grey-secondary ${
              viewType === 1 && "text-white bg-primary border-primary"
            }`}>
            Day
          </button>
          <button
            onClick={() => setViewType(2)}
            className={`px-8 py-1.5  border-y-[1px] border-grey-secondary ${
              viewType === 2 && "text-white bg-primary border-primary"
            }`}>
            Week
          </button>
          <button
            onClick={() => setViewType(3)}
            className={`px-8 py-1.5 rounded-r-md border-[1px] border-grey-secondary ${
              viewType === 3 && "text-white bg-primary border-primary"
            }`}>
            Month
          </button>
        </div>
      </div>
      {/* days row */}
      {viewType === 1 ? (
        <CalendarDays appointmentData={appointmentData} />
      ) : viewType === 2 ? (
        <CalendarWeek appointmentData={appointmentData} />
      ) : (
        <CalendarMonth appointmentData={appointmentData} />
      )}
    </div>
  );
};

export default Calender;
