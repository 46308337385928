import produce from "immer";
import create, { State } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import { IAuthInfo, IServiceProvider } from "../../interfaces";

interface AuthState extends State {
  token: string;
  user: IServiceProvider;
  setAuthToken: (x: string) => void;
  setAuthState: (x?: IAuthInfo) => void;
  setAuthUser: (x: IServiceProvider) => void;
  updateAuthUser: (x: Partial<IServiceProvider>) => void;
}

const useAuthStore = create<AuthState>(
  persist(
    devtools(
      (set) => ({
        token: null,
        user: null,
        setAuthToken: (token) =>
          set(
            produce((state) => {
              state.token = token;
            }),
            false,
            "auth/setAuthToken"
          ),
        setAuthUser: (user) =>
          set(
            produce((state) => {
              state.user = user;
            }),
            false,
            "auth/setAuthUser"
          ),
        updateAuthUser: (changes) =>
          set(
            produce((state) => {
              state.user = { ...state.user, ...changes };
            }),
            false,
            "auth/updateAuthUser"
          ),
        setAuthState: (data) =>
          set(
            produce((state) => {
              state.token = data?.token || null;
              state.user = data?.user || null;
            }),
            false,
            "auth/setAuthState"
          ),
      }),
      { name: "auth", serialize: true }
    ),
    {
      name: "auth", // unique name
      getStorage: () => appConstants.keys.storage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useAuthStore;
