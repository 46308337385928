import produce from "immer";
import moment from "moment-timezone";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import { CalendarState } from "../../interfaces";

const useCalendarStore = create<CalendarState>(
  persist(
    devtools(
      (set) => ({
        day: moment(),
        nextDay: (newDate = null) =>
          set(
            produce((state) => {
              state.day =
                newDate === null ? moment(state.day).add(1, "day") : newDate;
            }),
            false,
            "calendar/nextDay"
          ),
        nextWeek: () =>
          set(
            produce((state) => {
              state.day = moment(state.day).add(1, "week");
            }),
            false,
            "calendar/nextWeek"
          ),
        nextMonth: () =>
          set(
            produce((state) => {
              state.day = moment(state.day).add(1, "month");
            }),
            false,
            "calendar/nextMonth"
          ),
        prevDay: () =>
          set(
            produce((state) => {
              state.day = moment(state.day).subtract(1, "day");
            }),
            false,
            "calendar/prevDay"
          ),
        prevWeek: () =>
          set(
            produce((state) => {
              state.day = moment(state.day).subtract(1, "week");
            }),
            false,
            "calendar/prevWeek"
          ),
        prevMonth: () =>
          set(
            produce((state) => {
              state.day = moment(state.day).subtract(1, "month");
            }),
            false,
            "calendar/prevMonth"
          ),
      }),
      { name: "calendar", serialize: true }
    ),
    {
      name: "calendar", // unique name
      getStorage: () => appConstants.keys.storage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useCalendarStore;
