import { Auth0Provider } from "@auth0/auth0-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { TourProvider } from "@reactour/tour";
import { IntlProvider } from "react-intl";
import { Outlet } from "react-router-dom";
import { DEFAULT_LOCALE, messages } from "../i18n";
import useLangStore from "../store/lang/lang.store";

import { ApolloProvider } from "@apollo/client";
import { client } from "../apollo";

const steps = [
  {
    selector: "#first-step",
    content: "This is my first Step",
  },
  {
    selector: "#second-step",
    content: "This is my second Step",
  },
];

function App() {
  const lang = useLangStore((state) => state.value);

  const locale = lang;
  return (
    <TourProvider steps={steps}>
      <IntlProvider
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        messages={messages[locale]}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
            <ApolloProvider client={client}>
              <Outlet />
            </ApolloProvider>
          </GoogleOAuthProvider>
        </Auth0Provider>
      </IntlProvider>
    </TourProvider>
  );
}

export default App;
