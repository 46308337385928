import { IASPeriod, IAvailabilitySchedule } from "../interfaces";

export class ASPeriodModel implements IASPeriod {
  dayOfWeek: number;
  startTime = "10:00";
  endTime = "17:00";

  constructor(dayOfWeek: number) {
    this.dayOfWeek = dayOfWeek;
  }
}

export class AvailabilityScheduleModel implements IAvailabilitySchedule {
  id = 0;
  name = "";
  default = false;
  periods: IASPeriod[] = [];
  overrides: unknown[] = [];
}
