import { useAuth0 } from "@auth0/auth0-react";
import { googleLogout } from "@react-oauth/google";
import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthStore } from "../../store";

const IS_AUTH0 = process.env.REACT_APP_AUTH_TYPE === "2";
const IS_OAUTH = process.env.REACT_APP_AUTH_TYPE === "3";

const Logout: React.FC = () => {
  const setAuthState = useAuthStore((state) => state.setAuthState);
  const { logout: Auth0Signout } = useAuth0();

  React.useEffect(() => {
    toast.success("Logged out successfully.");
    setAuthState(null);
    if (IS_AUTH0) {
      Auth0Signout({
        returnTo: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
      });
    }
    if (IS_OAUTH) {
      googleLogout();
    }
  }, [setAuthState, Auth0Signout]);

  return <Navigate to="/" />;
};

export default Logout;
