import React from "react";
import { useLocation } from "react-router-dom";
import { IOnboardingStep } from "../../../interfaces";
import { useServiceProvider } from "../hooks";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ONBOARDING_PROGRESS: IOnboardingStep[] = [
  { name: "Profile", status: "upcoming" },
  { name: "Availability", status: "upcoming" },
  { name: "Organization", status: "upcoming" },
];

export default function OnboardingSteps() {
  const serviceProvider = useServiceProvider(),
    { pathname } = useLocation(),
    [progress, setProgress] = React.useState<IOnboardingStep[]>([]);

  React.useEffect(() => {
    if (!pathname || !serviceProvider) {
      return;
    }

    if (pathname === "/onboarding") {
      setProgress([]);
      return;
    }

    const onboardingStatus = serviceProvider?.onboardingStatus,
      progressStatus = parseInt(onboardingStatus || "0", 10);

    setProgress(
      ONBOARDING_PROGRESS.map((step, index) => {
        return index === progressStatus
          ? { ...step, status: "current" }
          : index > progressStatus
          ? { ...step, status: "upcoming" }
          : { ...step, status: "complete" };
      })
    );
  }, [pathname, serviceProvider]);

  if (!progress.length) {
    return null;
  }

  return (
    <nav aria-label="Progress">
      <ol className="flex items-center">
        {progress.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== progress.length - 1 ? "pr-8 sm:pr-20 w-full" : "",
              "relative"
            )}>
            {step.status === "complete" ? (
              <React.Fragment>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true">
                  <div className="h-0.5 w-full bg-primary" />
                </div>
                <div className="relative flex items-center justify-center w-8 h-8 rounded-full bg-green-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">{step.name}</span>
                </div>
              </React.Fragment>
            ) : step.status === "current" ? (
              <React.Fragment>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div
                  className="relative flex items-center justify-center w-8 h-8 bg-white border rounded-full border-primary"
                  aria-current="step">
                  <span
                    className="w-4 h-4 rounded-full bg-primary"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div className="relative flex items-center justify-center w-8 h-8 bg-white border border-gray-300 rounded-full group hover:border-gray-400">
                  <span
                    className="w-4 h-4 bg-transparent rounded-full group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </div>
              </React.Fragment>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
